import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import './i18n'
import {App} from './App';
import {reportWebVitals} from './reportWebVitals';
import {ScrollToTop} from "./components/ScrollToTop";
import {AuthProvider} from "./components/hoc/AuthProvider";
import {LanguageProvider} from "./components/hoc/LanguageProvider";
import {AlertsProvider} from "./components/hoc/AlertsProvider";

ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider>
      <AuthProvider>
        <AlertsProvider>
          <BrowserRouter>
            <ScrollToTop/>
            <App/>
          </BrowserRouter>
        </AlertsProvider>
      </AuthProvider>
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
