import React, {useState, useEffect, FormEvent} from 'react';
import {useTranslation} from "react-i18next";
import {useAuth} from '../components/hoc/AuthProvider';
import {useAlerts} from '../components/hoc/AlertsProvider';
import {useVerify} from "../components/hoc/VerifyProvider";
import {ToastContainer} from "react-toastify";
import {DatePickerComponent} from "../components/DatePickerComponent";
import {styled, FormGroup, FormControlLabel, Switch, SwitchProps, Stack, Typography, FormControl, Checkbox} from '@mui/material';
import {ReactComponent as Unchecked} from "../assets/image/icons/unchecked.svg";
import {ReactComponent as Checked} from "../assets/image/icons/checked.svg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {ReactComponent as Pencil} from "../assets/image/icons/pencil.svg";
import {ReactComponent as DeleteAccount} from "../assets/image/icons/delete-account.svg";
import {AccountCircle} from "@mui/icons-material";
import {AddressAutocompleteComponent} from "../components/AddressAutocompleteComponent/AddressAutocompleteComponent";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import {AvatarEditorComponent} from "../UI/Popups/AvatarEditorComponent";
// import {AccountUserGallery} from "../components/AccountUserGallery";
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import {IndeterminateCheckbox} from "../components/Indeterminate";

interface AccountPageProps {
  lang: string;
  categories: any[];
}

export const AccountPage: React.FC<AccountPageProps> = ({lang, categories}) => {
  const {t} = useTranslation();
  const {user, setUser, updateMail, onChangePassword, API_URL, SERVER_URL, setIsAuth} = useAuth();
  const {warningAlert, successAlert, errorAlert} = useAlerts();
  // @ts-ignore
  const {onEmailInput, onBlurEmail, onPasswordInput, onConfirmPasswordInput, onBlurPassword, onBlurConfirmPassword} = useVerify();
  // const [role, setRole] = useState<string>("");
  const [isOrganization, setIsOrganization] = useState<boolean>(false);
  const [availableToWork, setAvailableToWork] = useState<boolean>(true);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const [address, setAddress] = useState("");

  const [birthday, setBirthday] = useState<any>();

  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");

  const [phonePlaceholder, setPhonePlaceholder] = useState('+41 __ ___ __ __');

  const [phone1Error, setPhone1Error] = useState("");
  const [phone2Error, setPhone2Error] = useState("");
  const [phone3Error, setPhone3Error] = useState("");

  const [phoneInputStatus, SetPhoneInputStatus] = useState(() => !!phone1);

  const [oldPasswordInputType, setOldPasswordInputType] = useState<boolean>(true);
  const [newPasswordInputType, setNewPasswordInputType] = useState<boolean>(true);
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState<boolean>(true);
  const [validButton, setValidButton] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [isVerifyPhonesFormButton, setIsVerifyPhonesFormButton] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputAvatar, setInputAvatar] = useState<string>("");
  const [newAvatar, setNewAvatar] = useState<FileList | null>(null);

  useEffect(() => {
    if ((phone2 || phone3) && (!phone2Error || !phone3Error)) {
      setIsVerifyPhonesFormButton(true);
    } else setIsVerifyPhonesFormButton(false);
  }, [phone2, phone3, phone2Error, phone3Error]);

  const [about, setAbout] = useState("");

  // const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const [notifications, setNotifications] = useState({
    notifyOffers: false,
    notifyMessages: false,
    notifyOrdersStatusChanges: false,
    notifyPaymentPeriod: false,
  });

  useEffect(() => {
    if (user.id) {
      setName(user.name);
      setLastName(user.lastName);
      setPhone1(user.phone1);
      setPhone2(user.phone2);
      setPhone3(user.phone3);
      setEmail(user.email);
      setAbout(user.about);
      setBirthday(user.birthday || new Date());
      // setRole(user.role);
      setAvailableToWork(user.availableToWork);
      setNotifications(prev => {
        return {
          ...prev,
          notifyOffers: user.notifications.notifyOffers,
          notifyMessages: user.notifications.notifyMessages,
          notifyOrdersStatusChanges: user.notifications.notifyOrdersStatusChanges,
          notifyPaymentPeriod: user.notifications.notifyPaymentPeriod
        }
      });
      // setTwoFactorAuth(user.twoFactorAuth);
      if (user.isOrganization) {
        setIsOrganization(user.isOrganization);
      }
    }
  }, [user]);

  const updateData = async (data: any, path: string, successMessage: string, warningMessage: string, errorMessage: string) => {
    fetch(`${API_URL}${path}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...data,
        username: user.username
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status >= 400) {
          throw new Error(warningMessage);
        }
      })
      .then((user: IUser) => {
        successAlert(successMessage);
        setUser(user);
      })
      .catch(error => {
        warningAlert(error.message)
      });
  }

  const messagesAlerts: { success: string, warning: string, error: string } = {
    success: "Success!",
    warning: "Something went wrong",
    error: "Server not found"
  }

  const onUpdateNLA = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target;
    // @ts-ignore
    const formData = new FormData(form);

    const data = {};

    const path = "/user/update-user"

    formData.forEach((value, key) => {
      // @ts-ignore
      data[key] = value;
    });
    await updateData(data, path, messagesAlerts.success, messagesAlerts.warning, messagesAlerts.error);
  }

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))
  (({theme}) => ({
    width: 68,
    height: 36,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 4,
      transitionDuration: '300ms',
      color: '#759A8A',
      '&.Mui-checked': {
        transform: 'translateX(32px)',
        color: '#FFF',
        transitionDuration: '300ms',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#759A8A' : '#759A8A',
          opacity: 1,
          border: '2px solid #759A8A',
          transitionDuration: '300ms',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#759A8A',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 28,
      height: 28,
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      border: '2px solid #759A8A',
      borderRadius: 34,
      backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#FFFFFF',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  /*const onChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.checked);
  };*/

  const onChangeWork = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailableToWork(event.target.checked);
    const data = {
      availableToWork: event.target.checked
    }
    fetch(`${API_URL}/user/update-user`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...data,
        username: user.username
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 400) {
          warningAlert(messagesAlerts.warning)
        } else if (response.status === 404) {
          throw new Error(messagesAlerts.error);
        }
      })
      .then((user: IUser) => {
        setUser(user);
      })
      .catch(error => {
        warningAlert(error.message)
      });
  };

  /*const onChangeTwoFactorAuth = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setTwoFactorAuth(event.target.checked);
    fetch(`${API_URL}/user/update-user`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: user.username,
        twoFactorAuth: event.target.checked
      })
    })
      .then(response => {
        if(response.status === 200) {
          return response.json()
        } else if(response.status === 400) {
          warningAlert(messagesAlerts.warning)
        } else if(response.status === 404) {
          throw new Error(messagesAlerts.error);
        }
      })
      .then((user: IUser) => setUser(user))
      .catch(error => console.log(error))
  }*/

  const onChangeNotifications = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotifications({
      ...notifications,
      [event.target.name]: event.target.checked,
    });
    fetch(`${API_URL}/user/update-user`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        notifications: {
          ...notifications,
          [event.target.name]: event.target.checked,
        },
        username: user.username
      })
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 400) {
          warningAlert(messagesAlerts.warning)
        } else if (response.status === 404) {
          throw new Error(messagesAlerts.error);
        }
      })
      .then((user: IUser) => {
        setUser(user);
      })
      .catch(error => {
        warningAlert(error.message)
      });
  };

  const {notifyOffers, notifyMessages, notifyOrdersStatusChanges, notifyPaymentPeriod} = notifications;

  // const error = [offers, messages, status, payment].filter((v) => v).length !== 2;

  function setCursorPosition(pos: number, elem: EventTarget & HTMLInputElement, setPhone: React.Dispatch<React.SetStateAction<string>>) {
    elem.focus();
    if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
    else { // @ts-ignore
      if (elem.createTextRange) {
        // @ts-ignore
        let range = elem.createTextRange();
        range.collapse(true);
        range.moveEnd("character", pos);
        range.moveStart("character", pos);
        range.select();
        setPhone(range);
      }
    }
  }

  function mask(event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
                setPhone: React.Dispatch<React.SetStateAction<string>>,
                setError: React.Dispatch<React.SetStateAction<string>>
  ) {
    let matrix = `${phonePlaceholder}`,
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, "");
    if (def.length >= val.length) val = def;

    if (event.target.value.length > 0 && event.target.value.length < 16) {
      setError("Phone number is not valid")
    } else {
      setError("");
    }

    setPhone(matrix.replace(/./g, function (a) {
      return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
    }));
    if (event.type === "blur") {
      if (event.target.value.length === 3) {
        setPhone("");
        setError("");
      }
    } else setCursorPosition(event.target.value.length, event.target, setPhone)
  }

  const onUpdateMainPhone = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      phone1: phone1
    }
    const path = "/user/update-main-phone";

    await updateData(data, path, messagesAlerts.success, messagesAlerts.warning, messagesAlerts.error);
  }

  const onUpdateOptionPhones = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      phone2: phone2,
      phone3: phone3
    }
    const path = "/user/update-user";

    await updateData(data, path, messagesAlerts.success, messagesAlerts.warning, messagesAlerts.error);
  }

  useEffect(() => {
    if (oldPassword && (newPassword === confirmPassword) && !newPasswordError && !confirmPasswordError) {
      setValidButton(true)
    } else {
      setValidButton(false);
    }
  }, [oldPassword, newPassword, confirmPassword, newPasswordError, confirmPasswordError]);

  const onUpdateAvatar = async (event: FormEvent<HTMLFormElement>,
                                editor: React.MutableRefObject<null | Blob>,
                                setScale: React.Dispatch<React.SetStateAction<number>>,
                                setRotate: React.Dispatch<React.SetStateAction<number>>) => {
    event.preventDefault();
    // @ts-ignore
    const form = event.target;
    const formData = new FormData()
    // @ts-ignore
    const canvas = editor.current.getImageScaledToCanvas();
    const image = new Image(190, 190);
    image.src = canvas.toDataURL('image/png');
    const blob: Blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
    formData.append("avatar", blob);
    formData.append("username", `${user.username}`);
    const response = await fetch(`${API_URL}/user/update-user`, {
      method: "PUT",
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(user => setUser(user))
      .catch(error => console.log(error))
      .finally(() => {
        // @ts-ignore
        form.reset();
        setIsOpen(false);
        setScale(1);
        setRotate(0);
      })
  };

  const onAvatarEditorComponentOpen = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileName = event.target.value,
      idxDot = fileName.lastIndexOf(".") + 1,
      extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      if (event.target.files) {
        // @ts-ignore
        setNewAvatar(event.target.files[0]);
      }
      setIsOpen(true);
    } else {
      warningAlert("Only jpg/jpeg and png files are allowed!");
      setNewAvatar(null);
      setInputAvatar("")   // Reset the input so no files are uploaded
    }
  }

  const onDeleteUser = async () => {
    await fetch(`${API_URL}/user/delete`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: user.username
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw Error("Something went wrong")
        }
      })
      .then(data => {
        setUser({} as IUser);
        setIsAuth(false);
      })
      .catch(error => console.log(error))
  }

  return (
    user.id ? <>
      <div className={"account-page"}>
        <h1>{t("account page.title")}</h1>
        <div className={"content"}>
          <div className="left-block">
            <div className="personal-data-block">
              <h3>{t("account page.personal data.title")}</h3>
              <div className="personal-data-wrapper">
                <div className="avatar-block">
                  <div className="avatar-wrapper">
                    {user.avatar ? <img src={`${SERVER_URL}/${user.role}/${user.avatar}`} alt="avatar" className="avatar"/> : <AccountCircle/>}
                  </div>
                  <div className="addAvatarButtonBlock">
                    <label htmlFor="inputAvatar">
                      <AddPhotoAlternateOutlinedIcon/>
                      <input type="file"
                             id="inputAvatar"
                             className="inputAvatar"
                             name="avatar"
                             accept="image/jpg, image/jpeg, image/png, image/bmp"
                             value={inputAvatar}
                             onChange={(event) => onAvatarEditorComponentOpen(event)}
                      />
                    </label>
                  </div>
                </div>
                <div className="data-block">
                  <p className="username"><span>{user.name}</span> <span className="user-int">{user.lastName}</span></p>
                  <div className="contact-block">
                    {(user.phone1 || user.phone2 || user.phone3) &&
                      <div className="phone-block">
                        {user.phone1 && <p className="user-phone">{user.phone1}</p>}
                        {user.phone2 && <p className="user-phone">{user.phone2}</p>}
                        {user.phone3 && <p className="user-phone">{user.phone3}</p>}
                      </div>}
                    <p className={"user-email"}>{user.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="switch-block">
              {/*<FormControl>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{t("account page.switch block.client")}</Typography>
                  <IOSSwitch sx={{m: 1}} checked={role} onChange={onChangeRole}/>
                  <Typography>{t("account page.switch block.expert")}</Typography>
                </Stack>
              </FormControl>*/}
              {/*@ts-ignore*/}
              {user.role === "expert" && <form action="#">
                <FormControl>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{t("account page.switch block.available work")}</Typography>
                    <IOSSwitch sx={{m: 1}} checked={availableToWork} onChange={onChangeWork}/>
                    <Typography>{t("account page.switch block.unavailable work")}</Typography>
                  </Stack>
                </FormControl>
              </form>}
            </div>
            <div className="notifications-block">
              <h3>{t("account page.notification block.title")}</h3>
              <div className="notifications-wrapper">
                <form action="#">
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={notifyOffers} onChange={onChangeNotifications} name="notifyOffers"/>
                        }
                        label={t("account page.notification block.offers")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={notifyMessages} onChange={onChangeNotifications} name="notifyMessages"/>
                        }
                        label={t("account page.notification block.messages")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={notifyOrdersStatusChanges} onChange={onChangeNotifications}
                                    name="notifyOrdersStatusChanges"/>
                        }
                        label={t("account page.notification block.status")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={notifyPaymentPeriod} onChange={onChangeNotifications} name="notifyPaymentPeriod"/>
                        }
                        label={t("account page.notification block.payment")}
                      />
                    </FormGroup>
                  </FormControl>
                </form>
              </div>
            </div>
            {/*<div className="auth-block">
              <h3>{t("account page.auth block.title")}</h3>
              <div className="auth-wrapper">
                <FormControl>
                  <FormControlLabel
                    control={<IOSSwitch sx={{m: 1}} checked={twoFactorAuth} onChange={onChangeTwoFactorAuth}/>}
                    label={t("account page.auth block.turn auth")}
                    labelPlacement="start"
                  />
                </FormControl>
              </div>
            </div>*/}
            {/*{user && <AccountUserGallery user={user}/>}*/}
          </div>
          <div className="right-block">
            <div className="edit-personal-data">
              <form action="#" method={"PUT"} onSubmit={(event) => onUpdateNLA(event)}>
                <div className={"input-block"}>
                  <p>{t("account page.edit personal data.name")}*</p>
                  <div className="wrapper">
                    <label htmlFor="edit-username">
                      <input type="text"
                             id={"edit-username"}
                             value={name}
                             onChange={event => setName(event.target.value)}
                             placeholder={"Name"}
                             name={"name"}
                      />
                    </label>
                  </div>
                </div>
                <div className={"input-block"}>
                  <p>{t("account page.edit personal data.lastname")}*</p>
                  <div className="wrapper">
                    <label htmlFor="edit-user-last-name">
                      <input type="text"
                             id={"edit-user-last-name"}
                             value={lastName}
                             onChange={event => setLastName(event.target.value)}
                             placeholder={"Last name"}
                             name={"lastName"}
                      />
                    </label>
                  </div>
                </div>
                {user && <div className="username-block">
                  <p>{t("account page.edit personal data.username")}</p>
                  <p className="username">{user.username}</p>
                </div>}
                <div className={'input-block'}>
                  <p>{t("account page.about")}</p>
                  <div className="wrapper">
                <textarea name="about"
                          value={about}
                          onChange={event => setAbout(event.target.value)}
                          id="user-about"
                          placeholder="Shortly describe what types of work you do"
                />
                  </div>
                </div>
                <div className={"button-block"}>
                  <button type={"submit"} disabled={!(name && lastName)}>{t("apply")}</button>
                </div>
              </form>
            </div>
            <div className="since-block">
              <div className={"date-picker-block"}>
                {user.isOrganization ? <p>{t("account page.since")}</p> : <p>{t("account page.birthday")}</p>}
                <div className="wrapper">
                  {birthday && <DatePickerComponent birthday={birthday} updateData={updateData}/>}
                </div>
              </div>
            </div>
            <AddressAutocompleteComponent messagesAlerts={messagesAlerts} updateData={updateData}/>
            {(user.categories && user.categories.length > 0) && <div className="user-categories-block">
              <h3>{t("account page.about")}: <Pencil/></h3>
              <button type={"button"} className={categoriesOpen ? " open" : ""} onClick={() => setCategoriesOpen(!categoriesOpen)}>
                {t("account page.more categories")} <KeyboardArrowDownRoundedIcon/>
              </button>
              <div className={`user-categories-wrapper ${categoriesOpen ? " open" : ""}`}>
                <ul className="user-categories-content">
                  {user.categories.map((item: any, index: number) => <li className="user-categories-content-item"
                                                                         key={index}>{lang === "de" ? item.categoryDe : item.categoryEn}</li>)}
                </ul>
              </div>
            </div>}
            <div className="phone-password-email">
              <div className="user-phone-edit-block">
                <h3>{t("account page.phone.title")}: </h3>
                <div className="user-phone-edit-wrapper">
                  <form action="#" method={"PUT"} onSubmit={(event) => onUpdateMainPhone(event)}>
                    <div className={"phone1-block input-block"}>
                      <p>{t("account page.phone.main number")}*</p>
                      <div className="wrapper">
                        <label htmlFor="phone1">
                          <input type="tel"
                                 id={"phone1"}
                                 className={"phone1"}
                                 value={phone1}
                                 onChange={(event) => mask(event, setPhone1, setPhone1Error)}
                                 onBlur={(event) => mask(event, setPhone1, setPhone1Error)}
                                 onFocus={(event) => mask(event, setPhone1, setPhone1Error)}
                                 disabled={phoneInputStatus}
                                 placeholder={phonePlaceholder}
                          />
                        </label>
                        {phoneInputStatus && <Pencil onClick={() => SetPhoneInputStatus(false)}/>}
                        {phone1Error && <div className={"error-alert"}>{phone1Error}</div>}
                      </div>
                    </div>
                    <div className="button-block">
                      <button type={"submit"} className={"button"} disabled={(!!phone1Error || (phone1.length < 16))}>{t("apply")}</button>
                    </div>
                  </form>
                  <form action="#" method={"PUT"} onSubmit={(event) => onUpdateOptionPhones(event)}>
                    <div className={"phone2-block input-block"}>
                      <p>{t("account page.phone.optional number")} 1</p>
                      <div className="wrapper">
                        <label htmlFor="phone2">
                          <input type="tel"
                                 id={"phone2"}
                                 className={"phone2"}
                                 value={phone2}
                                 onChange={(event) => mask(event, setPhone2, setPhone2Error)}
                                 onBlur={(event) => mask(event, setPhone2, setPhone2Error)}
                                 onFocus={(event) => mask(event, setPhone2, setPhone2Error)}
                                 placeholder={phonePlaceholder}
                          />
                        </label>
                        {phone2Error && <div className={"error-alert"}>{phone2Error}</div>}
                      </div>
                    </div>
                    <div className={"phone3-block input-block"}>
                      <p>{t("account page.phone.optional number")} 2</p>
                      <div className="wrapper">
                        <label htmlFor="phone3">
                          <input type="tel"
                                 id={"phone3"}
                                 className={"phone3"}
                                 value={phone3}
                                 onChange={(event) => mask(event, setPhone3, setPhone3Error)}
                                 onBlur={(event) => mask(event, setPhone3, setPhone3Error)}
                                 onFocus={(event) => mask(event, setPhone3, setPhone3Error)}
                                 placeholder={phonePlaceholder}
                          />
                        </label>
                        {phone3Error && <div className={"error-alert"}>{phone3Error}</div>}
                      </div>
                    </div>
                    <div className={"button-block"}>
                      <button type={"submit"} disabled={!isVerifyPhonesFormButton}>{t("apply")}</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="change-password-block">
                <h3>{t("account page.password.title")}:</h3>
                <div className="change-password-wrapper">
                  <form action="#" method={"PUT"} onSubmit={(event) => onChangePassword(event, oldPassword, newPassword, warningAlert)}>
                    <div className={"old-password-block input-block"}>
                      <p>{t("account page.password.old")}</p>
                      <div className="wrapper">
                        <label htmlFor="old-password">
                          <input type={oldPasswordInputType ? "password" : "text"}
                                 id={"old-password"}
                                 className={"old-password"}
                                 name={"oldPassword"}
                                 value={oldPassword}
                                 onChange={(event) => setOldPassword(event.target.value)}/>
                        </label>
                        {oldPassword && (oldPasswordInputType ?
                          <VisibilityOutlinedIcon onClick={() => setOldPasswordInputType(!oldPasswordInputType)}/> :
                          <VisibilityOffOutlinedIcon onClick={() => setOldPasswordInputType(!oldPasswordInputType)}/>)}
                      </div>
                    </div>
                    <div className={"new-password-block input-block"}>
                      <p>{t("account page.password.new")}</p>
                      <div className="wrapper">
                        <label htmlFor="new-password">
                          <input type={newPasswordInputType ? "password" : "text"}
                                 id={"new-password"}
                                 className={"new-password"}
                                 name={"newPassword"}
                                 value={newPassword}
                                 onChange={(event) => onPasswordInput(event, setNewPassword, () => onBlurPassword(event, setNewPasswordError, confirmPassword, setConfirmPasswordError, t))}
                                 onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlurPassword(event, setNewPasswordError, confirmPassword, setConfirmPasswordError, t)}
                          />
                        </label>
                        {newPassword && (newPasswordInputType ?
                          <VisibilityOutlinedIcon onClick={() => setNewPasswordInputType(!newPasswordInputType)}/> :
                          <VisibilityOffOutlinedIcon onClick={() => setNewPasswordInputType(!newPasswordInputType)}/>)}
                      </div>
                      {newPasswordError && <div className={"error-alert"}>{newPasswordError}</div>}
                    </div>
                    <div className={"confirm-password-block input-block"}>
                      <p>{t("account page.password.confirm")}</p>
                      <div className="wrapper">
                        <label htmlFor="confirm-password">
                          <input type={confirmPasswordInputType ? "password" : "text"}
                                 id="confirm-password"
                                 className="confirm-password"
                                 name={"confirmPassword"}
                                 value={confirmPassword}
                                 onChange={(event) => onConfirmPasswordInput(event, setConfirmPassword, () => onBlurConfirmPassword(event, newPassword, setConfirmPasswordError, t))}
                                 onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlurConfirmPassword(event, newPassword, setConfirmPasswordError, t)}
                          />
                        </label>
                        {confirmPassword && (confirmPasswordInputType ?
                          <VisibilityOutlinedIcon onClick={() => setConfirmPasswordInputType(!confirmPasswordInputType)}/> :
                          <VisibilityOffOutlinedIcon onClick={() => setConfirmPasswordInputType(!confirmPasswordInputType)}/>)}
                      </div>
                      {confirmPasswordError && <div className={"error-alert"}>{confirmPasswordError}</div>}
                    </div>
                    <div className={"button-block"}>
                      <button type={"submit"} disabled={!validButton}>{t("apply")}</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className={"change-email-block"}>
                <h3>{t("account page.email.title")}:</h3>
                <div className="change-email-wrapper">
                  <div className="show-current-email">
                    <p className="help-text">{t("account page.email.current")}*</p>
                    <p className="current-user-email">{user && user.email}</p>
                  </div>
                  <form action="#" method="PUT" onSubmit={(event) => updateMail(event, successAlert, warningAlert)}>
                    <div className={"new-user-email-block input-block"}>
                      <p>{t("account page.email.new")}</p>
                      <div className="wrapper">
                        <label htmlFor="new-user-email">
                          <input type="email"
                                 id="new-user-email"
                                 className="new-user-email"
                                 name="email"
                                 value={""}
                                 onChange={event => onEmailInput(event, setEmail, () => onBlurEmail(event, setEmailError, t))}
                                 onBlur={event => onBlurEmail(event, setEmailError, t)}
                                 placeholder={"Enter your new email address"}
                          />
                        </label>
                        {emailError && <div className={"error-alert"}>{emailError}</div>}
                      </div>
                    </div>
                    <div className={"button-block"}>
                      <button type={"submit"} disabled={!!emailError}>{t("verify")}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="delete-account-wrapper">
              <button type={"button"} onClick={onDeleteUser}>{t("account page.delete profile")} <DeleteAccount/></button>
            </div>
          </div>
          {/*<IndeterminateCheckbox />*/}
        </div>
      </div>
      <AvatarEditorComponent onUpdateAvatar={onUpdateAvatar} newAvatar={newAvatar} setNewAvatar={setNewAvatar} isOpen={isOpen} setIsOpen={setIsOpen}/>
      <ToastContainer/>
    </> : <div style={{height: '110vh'}}></div>
  )
};