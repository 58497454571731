import React from 'react';
import {PortalComponent} from "../UI/Popups/PortalComponent";
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

interface ScrollToTopButtonProps {
  showHeader: boolean
}

function scrollTopTop(event:any) {
  event.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}

export const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({showHeader}) => {
  return <PortalComponent>
    <button type={"button"} id={"scroll-to-top"} className={showHeader ? "" : "hidden"} onClick={(event) => scrollTopTop(event)}>
      <ExpandLessRoundedIcon/>
    </button>
  </PortalComponent>
};