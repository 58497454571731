import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {useAuth} from './hoc/AuthProvider'
import {AppBar, Box, Toolbar, IconButton, Typography, Menu, Badge, Container, Avatar, Tooltip, MenuItem, styled, InputBase} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import {AccountCircle} from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import logo from "../assets/image/logo.png";

import {useLanguage} from "./hoc/LanguageProvider";

interface HeaderProps {
  showHeader: boolean
}

export const Header: React.FC<HeaderProps> = ({showHeader}) => {
  const {userId} = useParams();
  const {t, i18n} = useTranslation();
  const {user, logout, isAuth, SERVER_URL} = useAuth();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);
  const [userMainMenu, setUserMainMenu] = useState<IUserMainMenu[] | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const location = useLocation().pathname;
  const navigation = useNavigate();
  const [currentPath, setCurrentPath] = useState(location);

  const {lang, setLang} = useLanguage();

  useEffect(() => {
    setCurrentPath(location);
  }, [location]);

  const changeLanguage = async (lang: string): Promise<void> => {
    await i18n.changeLanguage(lang);
    setLang(lang);
    localStorage.setItem('I18N_LANGUAGE', lang);
  };

  useEffect(() => {
    if (user.id) {
      setUserMainMenu([
          {
            id: 0,
            menuItem: t("header.profileMenu.profile"),
            fun: () => {
              onCloseUserMenu()
            },
            link: `profile/${user.username}`
          },
          {
            id: 1,
            menuItem: t("header.profileMenu.account"),
            fun: () => {
              onCloseUserMenu()
            },
            link: `account`
          },
          {
            id: 2,
            menuItem: t("header.profileMenu.wallet"),
            fun: () => {
              onCloseUserMenu()
            },
            link: `wallet`
          },
          {
            id: 3,
            menuItem: t("header.profileMenu.orders"),
            fun: () => {
              onCloseUserMenu()
            },
            link: `orders/${user.username}`
          },
          {
            id: 4,
            menuItem: t("header.profileMenu.reviews"),
            fun: () => {
              onCloseUserMenu()
            },
            link: `#`
          },
          {
            id: 5,
            menuItem: t("header.profileMenu.dashboard"),
            fun: () => {
              onCloseUserMenu()
            },
            link: `dashboard`
          },
          {
            id: 6,
            menuItem: t("header.profileMenu.logout"),
            fun: () => {
              onCloseUserMenu();
              logout(user.username);
              if (currentPath === `/account` || currentPath === `/aufgabe/${userId}/bearbeiten`) {
                navigation("/", {replace: true});
              }
            }
          }
        ]
      )
    }
  }, [isAuth, currentPath, lang, user]);

  const Search = styled('div')(({theme}) => ({
    position: 'relative',
    color: '#000000',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#979494'
  }));

  const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: '#000000',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '30ch'
      },
    },
  }));

  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });

  useEffect(() => {
    document.documentElement.lang = `${lang}`
  }, [lang]);

  const onOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const onOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };

  const onCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onCloseUserMenu = () => {
    setAnchorUserMenu(null);
  };

  return (
    <AppBar position="static" className={showHeader ? "sticky" : ""}>
      <Container maxWidth="xl" className="content">
        <Toolbar disableGutters sx={{width: "100%"}}>
          {windowWidth < 1024 ? <Box>
            {/*@ts-ignore*/}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={onOpenNavMenu}
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={onCloseNavMenu}
              sx={{display: "block"}}
            >
              <MenuItem onClick={onCloseNavMenu}>
                <button type={"button"}
                        className="button create-an-order">
                  {t("header.createOrderButton")}
                </button>
              </MenuItem>
              <MenuItem>
                <SearchIconWrapper>
                  <SearchIcon/>
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={t("search.placeholder")}
                  inputProps={{'aria-label': 'search'}}
                />
              </MenuItem>
              <MenuItem onClick={onCloseNavMenu}>
                <ul className="language-button-group">
                  <li>
                    <button className={"de" + (lang === "de" ? " active" : "")}
                            disabled={lang === "de"}
                            onClick={() => changeLanguage("de")}
                            type={"button"}>
                      De
                    </button>
                  </li>
                  <li>
                    <button className={"en" + (lang === "en" ? " active" : "")}
                            disabled={lang === "en"}
                            onClick={() => changeLanguage("en")}
                            type={"button"}>
                      En
                    </button>
                  </li>
                </ul>
              </MenuItem>
            </Menu>
          </Box> : <></>}
          <Link to="/" className="logo
          -link"><img src={logo} alt="logo" className={"logo-img"}/></Link>
          {windowWidth >= 1024 ? <Box className={"central-block"}>
            <button type={"button"}
                    className="button create-an-order">
              {t("header.createOrderButton")}
            </button>
            <Search>
              <SearchIconWrapper>
                <SearchIcon/>
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={t("search.placeholder")}
                inputProps={{'aria-label': 'search'}}
              />
            </Search>
            <ul className="language-button-group">
              <li>
                <button className={"de" + (lang === "de" ? " active" : "")}
                        disabled={lang === "de"}
                        onClick={() => changeLanguage("de")}
                        type={"button"}>
                  De
                </button>
              </li>
              <li>
                <button className={"en" + (lang === "en" ? " active" : "")}
                        disabled={lang === "en"}
                        onClick={() => changeLanguage("en")}
                        type={"button"}>
                  En
                </button>
              </li>
            </ul>
          </Box> : <></>}
          <Box sx={{flexGrow: 0}}>
            {isAuth ?
              <Box className={"dynamic-icons-wrapper"}>
                {windowWidth >= 768 ?
                  <Box sx={{display: "flex"}}>
                    <Tooltip title={t<string>("header.hover.messages")}>
                      <IconButton size="large" aria-label="show 4 new mails">
                        <Badge badgeContent={4} color="error">
                          <MailIcon/>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t<string>("header.hover.notifications")}>
                      <IconButton size="large" aria-label="show 17 new notifications">
                        <Badge badgeContent={17} color="error">
                          <NotificationsIcon/>
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Box> : <></>}
                <Tooltip title={t<string>("header.hover.menu")}>
                  {/*@ts-ignore*/}
                  <IconButton onClick={onOpenUserMenu} sx={{p: "0", marginLeft: {sx: "5px", md: "10px"}}}>
                    <Avatar alt={user.name} src={`${SERVER_URL}/${user.role}/${user.avatar}`}/>
                  </IconButton>
                </Tooltip>
              </Box> :
              <Box className={"login-button-block"}>
                {windowWidth >= 768 ? <ul className="login-button-group">
                    <li>
                      <Link to={"/auth/login"} className="login">{t("header.login")}</Link>
                    </li>
                    <li>
                      <Link to={"/auth/sign-up"} className="signUp">{t("header.signup")}</Link>
                    </li>
                  </ul> :
                  <Tooltip title="Open settings">
                    <Link to={"/auth/login"}>
                      <IconButton value={"login"} size={"large"} sx={{p: 0, ml: {sx: "5px", md: "10px"}}}>
                        <AccountCircle sx={{width: "40px", height: "40px"}}/>
                      </IconButton>
                    </Link>
                  </Tooltip>}
              </Box>}
            {windowWidth >= 768 ?
              <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                className={"userMainMenu"}
                anchorEl={anchorUserMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorUserMenu)}
                onClose={onCloseUserMenu}
              >
                {userMainMenu && userMainMenu.map((menuItem) => (
                  <MenuItem key={menuItem.id} onClick={menuItem.fun}>
                    {menuItem.link ?
                      <Link to={menuItem.link}>{menuItem.menuItem}</Link>
                      :
                      <Typography>{menuItem.menuItem}</Typography>}
                  </MenuItem>
                ))}
              </Menu> :
              <Menu
                sx={{mt: '45px',}}
                id="menu-appbar"
                className={"userMainMenu"}
                anchorEl={anchorUserMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorUserMenu)}
                onClose={onCloseUserMenu}
              >
                <MenuItem>
                  <IconButton size="large" aria-label="show 4 new mails">
                    <Badge badgeContent={4} color="error">
                      <MailIcon/>
                    </Badge>
                  </IconButton>
                  <p>Messages</p>
                </MenuItem>
                <MenuItem>
                  <IconButton size="large" aria-label="show 17 new notifications">
                    <Badge badgeContent={17} color="error">
                      <NotificationsIcon/>
                    </Badge>
                  </IconButton>
                  <p>Notifications</p>
                </MenuItem>
                {userMainMenu && userMainMenu.map((menuItem) => (
                  <MenuItem key={menuItem.id} onClick={menuItem.fun}>
                    {menuItem.link ?
                      <Link to={menuItem.link}>{menuItem.menuItem}</Link>
                      :
                      <Typography>{menuItem.menuItem}</Typography>}
                  </MenuItem>
                ))}
              </Menu>}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};