import React, {useEffect, useState} from 'react';
import {useAuth} from '../components/hoc/AuthProvider';
import {useParams, Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {ReactComponent as Reviews} from "../assets/image/icons/reviews.svg";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {ReactComponent as VerifyIcon} from "../assets/image/icons/verifiedBig.svg";
import {AccountCircle} from "@mui/icons-material";
import {ExpertOnMap} from "../components/ExpertOnMap";
// import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
// import {ReactComponent as Pencil} from "../assets/image/icons/pencil.svg";

// import {ExpertGallery} from "../components/ExpertGallery";

interface ProfilePageProps {
  lang: string
}

export const ProfilePage: React.FC<ProfilePageProps> = ({lang}) => {
  const {t} = useTranslation("translation");

  const {API_URL, SERVER_URL} = useAuth();

  const {username} = useParams();

  const [user, setUser] = useState<IUser>({} as IUser);

  const [sinceDate, setSinceDate] = useState("");
  const [sinceMonth, setSinceMonth] = useState("");
  const [sinceYear, setSinceYear] = useState("");

  const [lastVisitDate, setLastVisitDate] = useState("");
  const [lastVisitMonth, setLastVisitMonth] = useState("");
  const [lastVisitYear, setLastVisitYear] = useState("");

  const [categoriesOpen, setCategoriesOpen] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/user/${username}`, {
      method: "GET",
      credentials: "include"
    })
      .then(response => response.json())
      .then(user => {
          setUser(user);
          console.log('user: ', user);
        }
      )
      .catch(error => console.log(error));

    return () => {}
  }, []);

  useEffect(() => {
    if (user?.createdAt) {
      // @ts-ignore
      setSinceDate(new Date().getDate(user.createdAt));
      // @ts-ignore
      setSinceMonth(new Date().getMonth(user.createdAt));
      // @ts-ignore
      setSinceYear(new Date().getFullYear(user.createdAt));
    }
    if (user.lastVisit !== null) {
      // @ts-ignore
      setLastVisitDate(new Date().getDate(user.lastVisit));
      // @ts-ignore
      setLastVisitMonth(new Date().getMonth(user.lastVisit));
      // @ts-ignore
      setLastVisitYear(new Date().getFullYear(user.lastVisit));

    }
  }, [user]);

  if (!user.id) {
    return <></>
  } else return <div className={"profile-page"}>
    <h1>{t("profile page.title")}</h1>
    <div className="content">
      <header>
        <div className="user-avatar-block">
          {user.avatar ? <img src={`${SERVER_URL}/${user.role}/${user.avatar}`} alt="avatar" className="avatar"/> : <AccountCircle/>}
          <div className="username-block">
            <div className="username-wrapper">
              <p className="username"><span>{user.name}</span> <span className="user-int">{user.lastName[0]}.</span></p>
              {user.verified ? <VerifyIcon/> : <></>}
            </div>
            <button type={"button"} className={"send-message"}>{t("profile page.send message")}</button>
            <button type={"button"} className={"offer-button"}>{t("profile page.job offer")}</button>
          </div>
        </div>
        <section className="user-self-block">
          <h3>{t("profile page.about")}</h3>
          <div className="user-about-block">
            <p
              className="user-about">{user.about ? user.about : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."}</p>
          </div>
          <div className="summary">
            <div className="left-block">
              {
                (user.address.country && user.address.city) && <p className={"user-address"}>{user.address.country}, {user.address.city},<br/> {user.address.street}. {user.address.home}</p>
              }
              {(user.phone1 || user.phone2 || user.phone3) &&
                <div className="phone-block">
                  {user.phone1 && <p className="user-phone">{user.phone1}</p>}
                  {user.phone2 && <p className="user-phone">{user.phone2}</p>}
                  {user.phone3 && <p className="user-phone">{user.phone3}</p>}
                </div>}
              <p className={"user-email"}>{user.email}</p>
            </div>
            <div className="right-block">
              <p className={`user-available-work ${user.availableToWork ? "busy" : "ready"}`}>{user.availableToWork ? t("profile page.busy") : t("profile page.ready to work")}</p>
              <p className={`user-online ${user.online ? "online" : "offline"}`}>{user.online ? "online" : `Last visit: ${lastVisitDate}.${lastVisitMonth + 1}.${lastVisitYear}`}</p>
              <p className={"user-since"}>{t("profile page.joined")} {sinceDate}.{sinceMonth + 1}.{sinceYear}</p>
            </div>
          </div>
        </section>
      </header>
      <div className="main-content">
        <div className="user-rate-block">
          <h3>{t("rating.title")}</h3>
          <div className="user-rate-wrapper">
            <div className="content-inner-block">
              <img className="user-rate-icon" src="/image/icons/icon-great.svg" alt="icon rate"/>
              <div className={"user-description-wrapper"}>
                <span className={"user-rate-description"}>{t("rating.great")}</span>
                <p className={"finally-jobs"}>75% {t("rating.great reviews")}<br/> (75 {t("rating.jobs")})</p>
              </div>
              <Link to={"#"} className={"read-review-button"}>{t("rating.read reviews")} <Reviews/></Link>
            </div>
          </div>
        </div>
        {user?.categories && <div className="user-categories-block">
          <h3>{t("profile page.categories.title")}:</h3>
          <button type={"button"} className={categoriesOpen ? " open" : ""} onClick={() => setCategoriesOpen(!categoriesOpen)}>{t("profile page.categories.show all")}
            <KeyboardArrowDownRoundedIcon/></button>
          <div className={`user-categories-wrapper ${categoriesOpen ? " open" : ""}`}>
            <ul className="user-categories-content">
              {user.categories.map((item: any, index: any) => <li className="user-categories-content-item" key={index}>{lang === "de" ? item.categoryDe : item.categoryEn}</li>)}
            </ul>
          </div>
        </div>}
        {(user?.geo?.lat && user?.geo?.lng) && <ExpertOnMap user={user}/>}
        {/*{user?.gallery && <ExpertGallery user={user}/>}*/}
      </div>
    </div>
  </div>
};