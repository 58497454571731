import React, {useEffect, useState} from "react";
import {useNavigate, useLocation, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAuth} from "../components/hoc/AuthProvider";
import {useAlerts} from "../components/hoc/AlertsProvider";
import {ToastContainer} from "react-toastify";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {InputTextField} from "../UI/InputFields/InputTextField";
import {InputPasswordField} from "../UI/InputFields/InputPasswordField";
import {useVerify} from "../components/hoc/VerifyProvider";

interface RegistrationComponentProps {

}

export const RegistrationForm: React.FC<RegistrationComponentProps> = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const fromPage = location.state?.from?.pathname || "/";

  const {onEmailInput,
    onBlurEmail,
    onUsernameInput,
    onBlurUsername,
    onPasswordInput,
    onConfirmPasswordInput,
    onBlurConfirmPassword,
    onBlurPassword} = useVerify();

  const [role, setRole] = useState("expert");
  const [termsPolicyCheck, setTermsPolicyCheck] = useState<boolean>(true);

  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [emailInputError, setEmailInputError] = useState<string>('');
  const [usernameInputError, setUsernameInputError] = useState<string>('');
  const [passwordInputError, setPasswordInputError] = useState<string>('');
  const [confirmPasswordInputError, setConfirmPasswordInputError] = useState<string>('');
  const [confirmPolicyError, setConfirmPolicyError] = useState<string>('');

  const [formValid, setFormValid] = useState<boolean>(false);

  const [inputType, setInputType] = useState<boolean>(true);
  const [confirmPassType, setConfirmPassType] = useState<boolean>(true);

  useEffect(() => {
    if (!termsPolicyCheck) {
      setConfirmPolicyError(t("form.notConfirmTerms"))
    } else setConfirmPolicyError('');
    if ((email && !emailInputError) && (username && !usernameInputError) && (password && !passwordInputError) && (password === confirmPassword) && termsPolicyCheck) {
      setFormValid(true);
    } else setFormValid(false);
  }, [email, emailInputError, username, usernameInputError, password, passwordInputError, confirmPassword, termsPolicyCheck]);

  const {t} = useTranslation();
  const {signUp} = useAuth();
  const {errorAlert, warningAlert, emailAlready} = useAlerts();

  function onChangeRole(event: React.MouseEvent<HTMLButtonElement>): void {
    // @ts-ignore
    setRole(event.target.value);
  }

  const onRegister = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const userRole = role;
    const email = form.email.value.trim();
    const username = form.username.value.trim();
    const password = form.password.value.trim();
    signUp(userRole, email, username, password, setEmail, setUsername, setPassword, setConfirmPassword, setFormValid, errorAlert, () => navigate(`${fromPage}`, {replace: true}))
  };

  return (
    <>
      <div className="form registration-form">
        <h3>{t("form.regFormTitle")}</h3>
        <div className="form-block">
          <form action="src/components/RegistrationForm#" method={"POST"} onSubmit={event => onRegister(event)}>
            <div className="role-button-tabs">
              <button type={"button"} onClick={event => onChangeRole(event)} value={"client"}
                      className={"role-button" + (role === "client" ? " active" : "")}>{t("form.client")}</button>
              <button type={"button"} onClick={event => onChangeRole(event)} value={"expert"}
                      className={"role-button" + (role === "expert" ? " active" : "")}>{t("form.expert")}</button>
            </div>
            <InputTextField value={email}
                            setValue={setEmail}
                            onValueInput={onEmailInput}
                            onBlurField={onBlurEmail}
                            valueInputError={emailInputError}
                            setValueInputError={setEmailInputError}
                            placeholder={"Email"}
                            type={"email"}
                            name={"email"}
                            id={"email"}
            />
            <InputTextField value={username}
                            setValue={setUsername}
                            onValueInput={onUsernameInput}
                            onBlurField={onBlurUsername}
                            valueInputError={usernameInputError}
                            setValueInputError={setUsernameInputError}
                            placeholder={t("form.username")}
                            type={"text"}
                            name={"username"}
                            id={"username"}
            />
            <div className={`input-container ${passwordInputError ? 'error' : ''}`}>
              <input type={inputType ? "password" : "text"}
                     className="form-input"
                     name={"password"}
                     value={password}
                     onChange={(event) => onPasswordInput(event, setPassword, () => onBlurPassword(event, setPasswordInputError, confirmPassword, setConfirmPasswordInputError, t))}
                     onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlurPassword(event, setPasswordInputError, confirmPassword, setConfirmPasswordInputError, t)}
                     required
                     id="password"
                     placeholder={" "}
              />
              {password && (inputType ?
                <VisibilityOutlinedIcon onClick={() => setInputType(!inputType)}/> :
                <VisibilityOffOutlinedIcon onClick={() => setInputType(!inputType)}/>)}
              <div className="cut">{t("form.password")}</div>
              <label className={"placeholder"} htmlFor="password">{t("form.password")}</label>
            </div>
            {passwordInputError && <div className={'error-alert'}>{passwordInputError}</div>}
            <div className={`input-container ${confirmPasswordInputError ? 'error' : ''}`}>
              <input type={confirmPassType ? "password" : "text"}
                     className="form-input"
                     name={"confirmPassword"}
                     value={confirmPassword}
                     onChange={(event) => onConfirmPasswordInput(event, setConfirmPassword, () => onBlurConfirmPassword(event, password, setConfirmPasswordInputError, t))}
                     onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlurConfirmPassword(event, password, setConfirmPasswordInputError, t)}
                     required
                     id="confirm-password"
                     placeholder={" "}
              />
              {confirmPassword && (confirmPassType ?
                <VisibilityOutlinedIcon onClick={() => setConfirmPassType(!confirmPassType)}/> :
                <VisibilityOffOutlinedIcon onClick={() => setConfirmPassType(!confirmPassType)}/>)}
              <div className="cut">{t("form.confirmPassword")}</div>
              <label className={"placeholder"} htmlFor="confirm-password">{t("form.confirmPassword")}</label>
            </div>
            {confirmPasswordInputError && <div className={'error-alert'}>{confirmPasswordInputError}</div>}
            <div className="terms-n-policy">
              <div className="custom-check">
                <input type="checkbox"
                       className="terms-n-policy-check"
                       id={"terms-n-policy-check"}
                       value={"terms-n-policy-check"}
                       checked={termsPolicyCheck}
                       onChange={() => setTermsPolicyCheck(!termsPolicyCheck)}
                />
                <label htmlFor="terms-n-policy-check">
                  {t("form.agree")}&nbsp;<a href="src/components/RegistrationForm#" className={"link-black"}>{t("form.termsOfService")}&nbsp;</a>
                  {t("form.and")}&nbsp;<a href="src/components/RegistrationForm#" className={"link-black"}>{t("form.PP")}</a>
                </label>
              </div>
              {confirmPolicyError && <div className={'error-alert'}>{confirmPolicyError}</div>}
            </div>
            <button type={"submit"} disabled={!formValid} className="button continue">{t("form.continue")}</button>
          </form>
          <div className="orWrapper">
            <span className={"or"}>{t("form.orRegister")}</span>
          </div>
          <div className="social-buttons-block">
            {/*<button type={"button"} className="social-button facebook-button">{t("regForm.socBtn")} Facebook</button>*/}
            <button type={"button"} className="social-button google-button">Google</button>
            <button type={"button"} className="social-button apple-button">Apple</button>
            {/*<button type={"button"} className="social-button microsoft-button">{t("regForm.socBtn")} Microsoft</button>*/}
          </div>
        </div>
        <div className="form-footer">
          <div className="form-footer-wrapper">
            <span>{t("form.member")}</span>
            <Link to={"/auth/login"} className={"signIn-button link-black"}>{t("form.logIn")}</Link>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  )
}