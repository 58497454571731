import React, {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {CategoryBlock} from "../components/CategoryBlock";
import {HowDoWeWork} from "../components/HowDoWeWork/HowDoWeWork";
import {Testimonials} from "../components/Testimonials/Testimonials";
import {SearchForm} from "../components/SearchForm";
import {BannerBlock} from "../components/BannerBlock";

interface SubcategoriesPageProps {
  categories: any,
  lang: string,
  testimonials: ITestimonials[]
}

export const CategoriesPage: React.FC<SubcategoriesPageProps> = ({lang, testimonials}) => {
  const navigate = useNavigate();
  const {categoryLink} = useParams();
  const [category, setCategory] = useState<any>([]);

  useEffect(() => {
    if(category === undefined) {
        navigate("/not-found-page", {replace: true});
      }
  }, [category]);

  useEffect(() => {
    fetch('/db/categories.json')
      .then(data => data.json())
      .then(categories => setCategory(categories.find((item: any) => item.categoryLink === categoryLink)))
      .catch(error => {
        console.log(error);
      });
  }, [categoryLink]);

  useEffect(() => {
    return () => {
    }
  }, []);

  return <div>
    <main className="main-content">
      <div className="content">
        <h1>{category && (lang === "de" ? category.categoryDe : category.categoryEn)}</h1>
      </div>
      <BannerBlock/>
      <div className="content">
        <SearchForm/>
        {category && <CategoryBlock categories={category.subcategories} lang={lang}/>}
      </div>
      <HowDoWeWork/>
      <div className="content">
        {testimonials && <Testimonials testimonials={testimonials}/>}
      </div>
    </main>
  </div>
}