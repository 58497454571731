import React, {useEffect} from 'react';
import {Navigate, useLocation, Routes, Route} from 'react-router-dom';
import {AlertsProvider} from "../components/hoc/AlertsProvider";
import {LoginFormPage} from "./LoginFormPage";
import {RegistrationForm} from "./RegistrationForm";
import {ForgotPasswordFormPage} from "./ForgotPasswordFormPage";
import {ResetPasswordPage} from "./ResetPasswordPage";
import {VerifyPage} from "./VerifyPage";
import {RequireAuth} from "../components/hoc/RequireAuth";


interface LoginPageProps {

}

export const AuthPage: React.FC<LoginPageProps> = () => {
  const location = useLocation();

  useEffect(() => {
    return () => {

    }
  }, []);

  if (location.pathname === '/auth') {
    return <Navigate to='/auth/login' replace={true}/>
  } else return (
    <AlertsProvider>
      <div className={"auth-page"}>
        <div className="content">
          <Routes>
            <Route path={"login"} element={<LoginFormPage/>}
            />
            <Route path={"sign-up"} element={<RegistrationForm/>}
            />
            <Route path={"forgot-password"} element={<ForgotPasswordFormPage/>}
            />
            <Route path={"reset-password"} element={<ResetPasswordPage/>}
            />
            <Route path="verify" element={<RequireAuth>
              <VerifyPage/>
            </RequireAuth>}
            />
          </Routes>
        </div>
      </div>
    </AlertsProvider>
  )
};