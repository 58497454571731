import React, {createContext, useContext, useState} from 'react';
import {useLanguage} from "./LanguageProvider";

export const AuthContext = createContext({} as IAuthProvider);

export const AuthProvider: React.FC = ({children}) => {
  const [loading, setLoading] = useState(false);
  const {lang} = useLanguage();

  function firstInit() {
    return !!localStorage.getItem("token");
  }

  const [user, setUser] = useState<IUser>({} as IUser);
  const [isAuth, setIsAuth] = useState(firstInit);
  // const [isOpen, setIsOpen] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  // const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

  const token = localStorage.getItem('token');

  const path = {
    login: "/auth/login",
    registration: "/auth/registration",
    logout: "/auth/logout",
    refresh: "/auth/refresh",
    updateEmail: "/auth/update-email",
    changePassword: "/auth/change-password"
  }

  const login = async (username: string,
                       password: string,
                       setUsername: React.Dispatch<React.SetStateAction<string>>,
                       setPassword: React.Dispatch<React.SetStateAction<string>>,
                       setFormValid: React.Dispatch<React.SetStateAction<boolean>>,
                       errorAlert: (message: string) => void,
                       redirectUser: () => void) => {
    await fetch(`${API_URL}${path.login}`, {
      method: "PUT",
      credentials: "include",
      referrerPolicy: "unsafe-url",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        username,
        password
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("")
        }
      })
      .then((data) => okFunc(data))
      .catch(error => {
        console.log(error);
        errorAlert("Something went wrong");
      })
      .finally(() => {
        setFormValid(true);
      });

    function okFunc(data: any) {
      setUsername('');
      setPassword('');
      localStorage.setItem("token", data.accessToken);
      setUser(data.user);
      setIsAuth(true);
      redirectUser();
    }
  };

  const signUp = async (userRole: string,
                  email: string,
                  username: string,
                  password: string,
                  setEmail: React.Dispatch<React.SetStateAction<string>>,
                  setUsername: React.Dispatch<React.SetStateAction<string>>,
                  setPassword: React.Dispatch<React.SetStateAction<string>>,
                  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>,
                  setFormValid: React.Dispatch<React.SetStateAction<boolean>>,
                  errorAlert: (message: string) => void,
                  redirectUser: () => void) => {
    setFormValid(false);
    fetch(`${API_URL}${path.registration}`, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        role: userRole,
        email,
        username,
        password
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          // @ts-ignore
          throw new Error(response?.data?.message);
        }
      })
      // @ts-ignore
      .then((data) => okFunc(data))
      .catch(error => {
        errorAlert("Something went wrong");
      })
      .finally(() => {
        setEmail('');
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setFormValid(true);
      });

    function okFunc(data: any) {
      redirectUser();
    }
  };

  const logout = async (username: string) => {
    fetch(`${API_URL}${path.logout}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          // @ts-ignore
          throw new Error(response?.data?.message);
        }
      })
      .then(() => {
        setUser({} as IUser);
        setIsAuth(false);
        localStorage.removeItem("token");
      })
      .catch(error => console.log(error.response?.data?.message))
  };

  const checkAuth = async () => {
    setLoading(false);
    fetch(`${API_URL}${path.refresh}`, {
      credentials: "include",
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          setIsAuth(false);
          logout(user.username);
          throw new Error("Not authorized");
        }
      })
      .then(data => {
        localStorage.setItem("token", data.accessToken);
        setIsAuth(true);
        setUser(data.user);

      })
      .catch(error => console.log("error: ", error))
      .finally(() => {
        // window.onload = () => {
          setTimeout(() => {
            setLoading(true);
          }, 1000);
        // }
      })
  };

  const updateMail = async (event: React.FormEvent<HTMLFormElement>,
                            successAlert: (message: string) => void,
                            warningAlert: (message: string) => void,
                            setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>) => {
    event.preventDefault();
    const form = event.target;
    await fetch(`${API_URL}${path.updateEmail}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: user.username,
        role: user.role,
        // @ts-ignore
        email: form.email.value,
      })
    })
      .then((response) => {
        {
          if (response.status === 200) {
            return response.json();
          } else if(response.status >= 400) {
            throw new Error(`${lang === "en" ? "Email already exists" : "E-Mail ist bereits vorhandenE-Mail ist bereits vorhanden"}`);
          }
        }
      })
      .then(data => {
        successAlert('Email was updated');
        setUser(data);
      })
      .catch(error => {
        warningAlert(error.message);
      })
      .finally(() => {
        if (setIsOpen) {
          setIsOpen(false);
        }
        // @ts-ignore
        form.reset();
      })
  };

  const onChangePassword = async (event: React.FormEvent<HTMLFormElement>, oldPassword: string, newPassword: string, warningAlert: (alert: string) => void) => {
    event.preventDefault();

    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }

    if (oldPassword === newPassword) {
      warningAlert("New password and old password are confirmed")
    } else {
      fetch(`${API_URL}${path.changePassword}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          username: user.username
        })
      })
        .then(response => {
          if(response.ok) {
            return response.json()
          } else {
            throw new Error("")
          }
        })
        .then(data => okFunc(data) )
        .catch(error => {
          warningAlert(error.message)
        })
    }
    function okFunc(data: any) {
      logout(user.username);
      console.log(data);
    }
  };

  const value = {user, setUser, login, signUp, logout, checkAuth, isAuth, API_URL, SERVER_URL, updateMail, loading, setLoading, onChangePassword,
    token, path, setIsAuth}

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>
};

export const useAuth = () => {
  return useContext(AuthContext);
};