import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useVerify} from "../components/hoc/VerifyProvider";
import {useTranslation} from 'react-i18next';

interface ForgotPasswordFormPageProps {

}

export const ForgotPasswordFormPage: React.FC<ForgotPasswordFormPageProps> = ({}) => {
  const [role, setRole] = useState("expert");
  const {t} = useTranslation();

  const {onEmailInput, onBlurEmail} = useVerify();

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [isSubmit, setIsSubmit] = useState(false);
  const [formValid, setFormValid] = useState<boolean>(false);

  const navigate = useNavigate();

  function onChangeRole(event: React.MouseEvent<HTMLButtonElement>): void {
    // @ts-ignore
    setRole(event.target.value);
  }

  useEffect(() => {
    if (email && !emailError) {
      setFormValid(true)
    } else {
      setFormValid(false);
    }
  }, [email]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target;
    if (!email) {
      return
    } else {
      fetch('/db/', {
        method: "POST",
        body: JSON.stringify({
          email: email
        })
      })
        .then(response => response.json())
        .then(() => {
          setIsSubmit(true);
        })
        .catch(error => console.log(error))
        .finally(() => {
          // @ts-ignore
          form.reset();
        });
    }
    setIsSubmit(true);
    setTimeout(() => {
      navigate("/", {replace: true});
    }, 5000);
  }

  if (isSubmit) {
    return <>
      <div className="email-sent">
        <p>
          {t("form.emailSentNotification")}
        </p>
      </div>
    </>
  } else return (
    <div className={"form forgotPassword-form"}>
      <h3>{t("form.forgotPasswordFormTitle")}</h3>
      <div className="form-block">
        <form action="#" method={"POST"} onSubmit={(event) => onSubmit(event)}>
          <p className={"profile-type"}>{t("form.profileType")}</p>
          <div className="role-button-tabs">
            <button type={"button"}
                    onClick={(event) => onChangeRole(event)}
                    value={"client"}
                    className={`role-button ${role === "client" ? "active" : ""}`}>
              {t("form.client")}
            </button>
            <button type={"button"}
                    onClick={(event) => onChangeRole(event)}
                    value={"expert"}
                    className={`role-button ${role === "expert" ? "active" : ""}`}>
              {t("form.expert")}
            </button>
          </div>
          <div className="input-block">
            <p className={""}>{t("form.enterEmail")}</p>
            <div className={`input-container ${emailError ? 'error' : ''}`}>
              <input type="email"
                     className="form-input"
                     onBlur={((event: React.FocusEvent<HTMLInputElement>) => onBlurEmail(event, setEmailError, t))}
                     name={"email"}
                     value={email}
                     onChange={(event) => onEmailInput(event, setEmail, () => onBlurEmail(event, setEmailError, t))}
                     required
                     id="email"
                     placeholder={" "}
              />
              <div className="cut">{"Email"}</div>
              <label className={"placeholder"} htmlFor="email">{"Email"}</label>
            </div>
            {emailError && <div className={'error-alert'}>{emailError}</div>}
            <button type={"submit"} disabled={!formValid} className={"button"}>{t("form.resetPassword")}</button>
          </div>
        </form>
      </div>
      <Link to={"/auth/login"} className={"custom-button link-black"}>{t("form.backToLogin")}</Link>
    </div>
  );
}