import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../components/hoc/LanguageProvider";
import {useAuth} from "../components/hoc/AuthProvider";
import {useVerify} from "../components/hoc/VerifyProvider";
import {useAlerts} from "../components/hoc/AlertsProvider";
import {useTranslation} from 'react-i18next';
import {ToastContainer} from "react-toastify";
import {InputTextField} from "../UI/InputFields/InputTextField";

interface VerifyPageProps {
}

export const VerifyPage: React.FC<VerifyPageProps> = ({}) => {
  const {lang} = useLanguage();
  const {user, API_URL, updateMail} = useAuth();
  const {onBlurEmail, onEmailInput} = useVerify();
  const {successAlert, warningAlert} = useAlerts();
  const [email, setEmail] = useState<string>('');
  const [emailInputError, setEmailInputError] = useState<string>('');
  const [isVerify, setIsVerify] = useState(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if(user.isActivated) {
      navigate('/', {replace: false});
    }
  }, [user]);

  const {t} = useTranslation();

  const resendEmail = async () => {
    try {
      const response = await fetch(`${API_URL}/auth/activate/resend-email`, {
        method: 'PUT',
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: user.username,
          role: user.role,
          email: user.email,
        })
      })
      if (response.ok) {
        successAlert(`${lang === "en" ? "Email was sent to your mailbox" : "E -Mail wurde an Ihre Mailbox gesendet"}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (emailInputError) {
      setIsVerify(false);
    } else if (email && !emailInputError) {
      setIsVerify(true);
    }
  }, [emailInputError]);

  return (
    <>
      <div className="verify">
        <p>
          {t("verifyPage.weSentAnEmail")}<br/>
          <span>{user.email}</span>
        </p>
        <p>{t("verifyPage.checkYourEmail")}</p>
        <div className="button-block">
          <button type="button" className="button" onClick={() => setIsOpen(true)}>{t("verifyPage.updateEmail")}</button>
          <button type="button" className="button" onClick={resendEmail}>{t("verifyPage.resentEmail")}</button>
        </div>
        <div className={`form update-email-form ${!isOpen ? "hidden" : ""}`}>
          <h3>{t("form.updateYourEmail")}</h3>
          <div className="form-block">
            <form action="#" method="PUT" onSubmit={(event) => updateMail(event, successAlert, warningAlert, setIsOpen)}>
              <InputTextField value={email}
                              setValue={setEmail}
                              onValueInput={onEmailInput}
                              onBlurField={onBlurEmail}
                              valueInputError={emailInputError}
                              setValueInputError={setEmailInputError}
                              placeholder={"Email"}
                              type={"email"}
                              name={"email"}
                              id={"email"}
              />
              <button type="submit" className="button continue" disabled={!isVerify}>{t("verify")}</button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}