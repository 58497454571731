import React, {Suspense, useEffect, useState} from 'react';
import {Routes, Route, useNavigate, Navigate, useLocation} from "react-router-dom";
import "./assets/scss/main.scss"
import {Layout} from "./components/Layout";
import {Homepage} from "./pages/Homepage";
import {CategoriesPage} from "./pages/СategoriesPage";
import {NotfoundPage} from "./pages/NotfoundPage";
import {SubcategoriesPage} from "./pages/SubcategoriesPage";
import {ScrollToTopButton} from "./components/ScrollToTopButton";
import {AuthPage} from "./pages/AuthPage";
import {RequireAuth} from "./components/hoc/RequireAuth";
import {AccountPage} from "./pages/AccountPage";
import {CreateTaskPage} from "./pages/CreateTaskPage";
import {TaskPage} from "./pages/TaskPage";
import {useAuth} from "./components/hoc/AuthProvider";
import {ProfilePage} from "./pages/ProfilePage";
// import {Snippet} from './UI/Snippet/Snippet';
// import {AddressAutocompleteComponent} from "./components/AddressAutocompleteComponent/AddressAutocompleteComponent";
import {VerifyProvider} from "./components/hoc/VerifyProvider";

export const App: React.FC = () => {
  const [lang, setLang] = useState<string>(localStorage.getItem("I18N_LANGUAGE") || "de");
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [testimonials, setTestimonials] = useState<ITestimonials[]>([]);
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [center, setCenter] = useState<ILocation>({
    lat: 50.9578353,
    lng: 6.8272406
  });

  const {checkAuth, isAuth, user, loading, setLoading} = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  /*function getPosition () {
    navigator.geolocation.getCurrentPosition(function (geoPosition) {
        let lat = geoPosition ? geoPosition.coords.latitude : 50.4497115;
        let lon = geoPosition ? geoPosition.coords.longitude : 30.5235707;
        setCenter((prev) => {
          return {
            ...prev,
            lat: lat,
            lng: lon
          }
        });
      },
      function (error) {
        console.log(error);
      }
    );
  }*/

  useEffect(() => {
    const urlTestimonials: URL = new URL("/db/testimonials.json", origin);
    fetching(urlTestimonials, setTestimonials);

    const urlCategories: URL = new URL("/db/categories.json", origin);
    fetching(urlCategories, setCategories);

    //getPosition ();
    if (localStorage.getItem("token")) {
      checkAuth();
    }  setTimeout(() => {
      setLoading(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isAuth && user.isActivated === false && location.pathname !== '/auth/verify') {
      navigate('/auth/verify', {replace: true});
    }
  }, [isAuth, user, location]);

  function fetching(urlName: URL, stateName: React.Dispatch<React.SetStateAction<ITestimonials[] | any>>) {
    const url = new URL(urlName, origin);
    fetch(url)
      .then(response => response.json())
      .then(data => stateName(data))
      .catch(error => console.log(error))
  }

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 300) {
      setShowHeader(true);
    } else if (window.scrollY === 0) {
      setShowHeader(false);
    }
  });

  // {categories && categories.forEach((item:any) => console.log(item.categoryDe.toLowerCase().split(" ").join("-")));}

return (
     <Suspense fallback={""}>
       <div className="App">
         <VerifyProvider>
           <Routes>
             <Route path={"/"} element={<Layout showHeader={showHeader}
                                                lang={lang}/>}>
               <Route index element={<Homepage categories={categories}
                                               lang={lang}
                                               testimonials={testimonials}/>}/>
               <Route path={"/:categoryLink"} element={<CategoriesPage categories={categories}
                                                                       lang={lang}
                                                                       testimonials={testimonials}/>}
               />
               <Route path={"/:categoryLink/:subcategoryLink"}
                      element={<SubcategoriesPage setCenter={setCenter}
                                                  center={center}
                                                  categories={categories}
                                                  lang={lang}
                                                  testimonials={testimonials}/>}
               />
               {<Route path={"account"} element={
                 <RequireAuth>
                   <AccountPage lang={lang}
                                categories={categories}
                   />
                 </RequireAuth>
               }/>}
               <Route path={"profile/:username"} element={<ProfilePage lang={lang}/>}/>
               //TODO Create global path "augfabe"
               <Route path={"aufgabe/:aufgabeId"} element={<TaskPage/>}/>
               <Route path={"aufgabe/:aufgabeId/bearbeiten"} element={
                 <RequireAuth>
                   <CreateTaskPage/>
                 </RequireAuth>
               }/>
               <Route path={"auth/*"} element={<AuthPage/>}/>
               <Route path={"not-found-page"} element={<NotfoundPage lang={lang}/>}/>
               <Route path={"*"} element={<Navigate to={"not-found-page"} replace={true}/>} />
             </Route>
           </Routes>
         </VerifyProvider>
         <ScrollToTopButton showHeader={showHeader}/>
         {/*<Snippet loading={loading}/>*/}
       </div>
     </Suspense>
   )
}