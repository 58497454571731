import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader, Autocomplete} from '@react-google-maps/api';
import {MultipleSelectCategories} from "./MultipleSelectCategories";
import {SelectBounds} from "./SelectBounds";

const GOOGLE_API_KEY: string | undefined = process.env["REACT_APP_GOOGLE_API_KEY"];

const containerStyle = {
  width: '100%',
  height: '600px',
  margin: "auto"
};

interface GoogleMapsTestProps {
  center: ILocation,
  users: IUser[],
  categories: any,
  lang: string
}

export const GoogleMapComponent: React.FC<GoogleMapsTestProps> = ({lang, center, users, categories}) => {
  const {t} = useTranslation("translation");
  const [map, setMap] = useState<google.maps.Map>();
  const [marker, setMarker] = useState<any>();
  const [centerMap, setCenterMap] = useState<ILocation>(center);
  const [libraries] = useState<('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]>(['places']);
  const [, setRectangleLocation] = useState<any>();
  const [getAutocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    // @ts-ignore
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries
  });

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
    initZoomControl(map);

    setTimeout(() => {
      map.panTo(centerMap);
    }, 1)
  }

  function initZoomControl(map: google.maps.Map) {
    (document.querySelector(".zoom-control-in") as HTMLElement).onclick =
      function () {
        map.setZoom(map.getZoom()! + 1);
      };

    (document.querySelector(".zoom-control-out") as HTMLElement).onclick =
      function () {
        map.setZoom(map.getZoom()! - 1);
      };

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.querySelector(".zoom-control") as HTMLElement
    );
  }

  useEffect(() => {
    updateExperts();
  }, [centerMap])

  const onMarkerClick = (index: number): void => {
    setMarker(index);
  }
  const pinGreenURL: URL = new URL("/image/icons/markerPinGreen.svg", origin);

  const updateExperts = () => {
    if (!map) return

    const bounds = map.getBounds() as google.maps.LatLngBounds
    let ne = bounds.getNorthEast()
    let sw = bounds.getSouthWest()

    const {lat: neLat, lng: neLng} = ne.toJSON()
    const {lat: swLat, lng: swLng} = sw.toJSON()

    return setRectangleLocation({neLat, neLng, swLat, swLng})
  };

  const onUnmount = React.useCallback(function callback(map) {
    map = null;
  }, []);

  const bounds = [2, 5, 10, 15, 30, 50, 75];

  const onSubmit = (event: any): void => {
    event.preventDefault();
    // const form = event.target;
    onPlaceChanged();
  }

  const onAutoCompleteIsLoad = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (getAutocomplete) {
      const {geometry} = getAutocomplete.getPlace();
      if (geometry) {
        const {
          // @ts-ignore
          location: {lat, lng}
        } = geometry;
        setCenterMap({lat: lat(), lng: lng()})
      }
    }
  }

  return isLoaded ? <div className={"google-map-block"}>
    <div className="search-expert-form">
      <form action="#" method={"POST"} name={"getMap"} onSubmit={onSubmit}>
        <div className="search-expert-form-wrapper">
          <MultipleSelectCategories lang={lang} categories={categories}/>
          <Autocomplete className={"autocomplete"}
                        onLoad={onAutoCompleteIsLoad}
                        onPlaceChanged={onPlaceChanged}
          >
            <input type="text"
                   className={"search-input"}
                   id={"autocomplete"}
                   name={"autocomplete"}
            />
          </Autocomplete>
          <div className="bounds-wrapper">
            <SelectBounds bounds={bounds}/>
            <button type="submit" className={"find-button"}>{t("find")}</button>
          </div>
        </div>
      </form>
    </div>
    <div className="google-map-wrapper">
      {/*@ts-ignore*/}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={centerMap}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false
        }}
        onClick={() => setMarker(null)}
      >
        <MarkerClusterer
        >
          {/*@ts-ignore*/}
          {(clusterer) => {

            return users.map((user: any, index: any) => (
              // @ts-ignore
              <Marker
                key={index}
                icon={{
                  url: `${pinGreenURL}`,
                  scaledSize: new google.maps.Size(40, 40),
                }}
                animation={google.maps.Animation.DROP}
                position={{
                  lat: user.geo.lat,
                  lng: user.geo.lng
                }}
                onClick={() => onMarkerClick(index)}
                clusterer={clusterer}
              >
                {/*@ts-ignore*/}
                {marker === index ? (<InfoWindow onCloseClick={() => setMarker(null)}>
                  <div className="expert-cart">
                    <img className="user-avatar" src={user.avatar} alt="avatar"/>
                    <div className="expert-data">
                      <p className="expert">
                        <span className="expert-name">{user.name}</span> <span className="expert-lastName">{user.lastName[0]}.</span>
                      </p>
                      <div className="expert-rate-block">
                        <img className="expert-rate-icon" src="/image/icons/icon-great.svg" alt="icon rate"/>
                        <div className={"expert-rate-wrapper"}>
                          <span className={"expert-rate-description"}>{t("rating.great")}</span>
                          <p className={"finally-jobs"}>(75 {t("rating.jobs")})</p>
                        </div>
                      </div>
                      <Link to={`/profile/${user.id}`} className="button expert-cart-see-more">{
                        t("seeMore")}</Link>
                    </div>
                  </div>
                </InfoWindow>) : null}
              </Marker>
            ));
          }}
        </MarkerClusterer>
      </GoogleMap>
      <div style={{display:"none"}}>
        <div className="controls zoom-control">
          <button className="zoom-control-in" title="Zoom In">+</button>
          <button className="zoom-control-out" title="Zoom Out">−</button>
        </div>
      </div>
    </div>
  </div> : <></>
};