import React, {createContext, useContext} from 'react';


interface IVerifyProvider {
  onEmailInput: (event: React.ChangeEvent<HTMLInputElement>,
                 setEmail: React.Dispatch<React.SetStateAction<string>>,
                 onBlurEmail: React.Dispatch<React.SetStateAction<typeof event>>) => void;
  onBlurEmail: (event: React.ChangeEvent<HTMLInputElement>,
                setEmailInputError: React.Dispatch<React.SetStateAction<string>>,
                t: (value: string) => string) => void;
  onUsernameInput: (event: React.ChangeEvent<HTMLInputElement>,
                    setUsername: React.Dispatch<React.SetStateAction<string>>,
                    onBlurUsername: React.Dispatch<React.SetStateAction<typeof event>>) => void;
  onBlurUsername: (event: React.ChangeEvent<HTMLInputElement>,
                   setUsernameInputError: React.Dispatch<React.SetStateAction<string>>,
                   t: (value: string) => string) => void;
  onPasswordInput: (event: React.ChangeEvent<HTMLInputElement>,
                    setPassword: React.Dispatch<React.SetStateAction<string>>,
                    onBlurPassword: React.Dispatch<React.SetStateAction<typeof event>>) => void;
  onBlurPassword: (event: React.ChangeEvent<HTMLInputElement>,
                   setPasswordInputError: React.Dispatch<React.SetStateAction<string>>,
                   confirmPassword: string,
                   setConfirmPasswordInputError: React.Dispatch<React.SetStateAction<string>>,
                   t: (value: string) => string) => void;
  onBlurConfirmPassword: (event: React.ChangeEvent<HTMLInputElement>,
                          password: string,
                          setConfirmPasswordInputError: React.Dispatch<React.SetStateAction<string>>,
                          t: (value: string) => string) => void;
  onConfirmPasswordInput: (event: React.ChangeEvent<HTMLInputElement>,
                           setConfirmPassword: React.Dispatch<React.SetStateAction<string>>,
                           onBlurConfirmPassword: React.Dispatch<React.SetStateAction<typeof event>>) => void;
}

export const VerifyContext = createContext({} as IVerifyProvider);

export const VerifyProvider: React.FC = ({children}) => {

  const onBlurEmail = (event: React.ChangeEvent<HTMLInputElement>,
                       setEmailError: React.Dispatch<React.SetStateAction<string>>,
                       t: (value: string) => string) => {
    const regexp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!event.target.value) {
      setEmailError(t("form.emptyField"));
    } else if (!regexp.test(String(event.target.value).toLowerCase())) {
      setEmailError(t("form.emailNotValid"))
    } else {
      setEmailError('');
    }
  };
  const onBlurUsername = (event: React.ChangeEvent<HTMLInputElement>,
                          setUsernameError: React.Dispatch<React.SetStateAction<string>>,
                          t: (value: string) => string) => {
    var nameRegex = /^[a-z0-9\-]+$/;
    if (!event.target.value) {
      setUsernameError(t("form.emptyField"));
    } else if (event.target.value.length < 3) {
      setUsernameError(t("form.usernameNotLong"));
    } else if(!nameRegex.test(String(event.target.value))) {
      setUsernameError(t("form.usernameCharacters"));
    } else {
      setUsernameError('');
    }
  };
  const onBlurPassword = (event: React.ChangeEvent<HTMLInputElement>,
                          setPasswordError: React.Dispatch<React.SetStateAction<string>>,
                          confirmPassword: string,
                          setConfirmPasswordError: React.Dispatch<React.SetStateAction<string>>,
                          t: (value: string) => string) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,24})");
    // let specialCharacters = new RegExp("(?=.*[!@#\$%\^&\*])");
    if (!event.target.value) {
      setPasswordError(t("form.emptyField"));
    } else if (!strongRegex.test(String(event.target.value))) {
      setPasswordError(t("form.passNotValid"));
    } else if (confirmPassword && event.target.value !== confirmPassword) {
      setConfirmPasswordError(t("form.passwordsDoesNotMatch"));
    } else {
      setPasswordError('');
      setConfirmPasswordError('');
    }
  };
  const onBlurConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>,
                                 password: string,
                                 setConfirmPasswordError: React.Dispatch<React.SetStateAction<string>>,
                                 t: (value: string) => string) => {
    if (!event.target.value) {
      setConfirmPasswordError(t("form.emptyField"));
    } else if (password && event.target.value !== password) {
      setConfirmPasswordError(t("form.passwordsDoesNotMatch"));
    } else {
      setConfirmPasswordError('');
    }
  };

  const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>,
                        setEmail: React.Dispatch<React.SetStateAction<string>>,
                        onBlurEmail: React.Dispatch<React.SetStateAction<typeof event>>) => {
    setEmail(event.target.value);
    onBlurEmail(event);
  };
  const onUsernameInput = (event: React.ChangeEvent<HTMLInputElement>,
                           setUsername: React.Dispatch<React.SetStateAction<string>>,
                           onBlurUsername: React.Dispatch<React.SetStateAction<typeof event>>) => {
    setUsername(event.target.value);
    onBlurUsername(event);
  };
  const onPasswordInput = (event: React.ChangeEvent<HTMLInputElement>,
                           setPassword: React.Dispatch<React.SetStateAction<string>>,
                           onBlurPassword: React.Dispatch<React.SetStateAction<typeof event>>) => {
    setPassword(event.target.value);
    onBlurPassword(event);
  };
  const onConfirmPasswordInput = (event: React.ChangeEvent<HTMLInputElement>,
                                  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>,
                                  onBlurConfirmPassword: React.Dispatch<React.SetStateAction<typeof event>>) => {
    setConfirmPassword(event.target.value);
    onBlurConfirmPassword(event);
  }

  const value = {onBlurEmail, onBlurUsername, onBlurPassword, onBlurConfirmPassword, onEmailInput, onUsernameInput, onPasswordInput, onConfirmPasswordInput}

  return (
    <VerifyContext.Provider value={value}>
      {children}
    </VerifyContext.Provider>
  );
}

export const useVerify = () => {
  return useContext(VerifyContext)
}