import React from 'react';
import {useLocation, Navigate} from "react-router-dom";
import {useAuth} from "../hoc/AuthProvider"
// @ts-ignore
export const RequireAuth = ({children})  => {
  const location = useLocation();
  const {isAuth} = useAuth();

  if(!isAuth) {
    return <Navigate to="/auth/login" replace={true} state={{from: location}}/>
  } else return children
};