import React, {FormEvent, useEffect, useRef, useState} from 'react';
import {OverlayComponent} from "./OverlayComponent";
import AvatarEditor from "react-avatar-editor";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface AvatarEditorComponentProps {
  onUpdateAvatar: (event: FormEvent<HTMLFormElement>,
                   editor: React.MutableRefObject<null | Blob>,
                   setScale: React.Dispatch<React.SetStateAction<number>>,
                   setRotate: React.Dispatch<React.SetStateAction<number>>) => void;
  newAvatar: any;
  setNewAvatar: React.Dispatch<React.SetStateAction<FileList | null>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AvatarEditorComponent: React.FC<AvatarEditorComponentProps> = ({onUpdateAvatar, newAvatar, setNewAvatar, isOpen, setIsOpen}) => {
  const [scale, setScale] = useState<number>(1);
  const [rotate, setRotate] = useState<number>(0);
  const editor = useRef(null);

  const onClose = () => {
    setScale(1);
    setRotate(0);
    setIsOpen(false);
    setNewAvatar(null);
  }

  return (
    <OverlayComponent onClose={onClose} isOpen={isOpen}>
      <div className="avatar-editor-block">
        <div className="close-button" onClick={onClose}>
          <CancelOutlinedIcon/>
        </div>
        <div className="form-block">
          <form action="#" method="PUT" onSubmit={(event: FormEvent<HTMLFormElement>) => onUpdateAvatar(event, editor, setScale, setRotate)}>
            <AvatarEditor
              image={newAvatar}
              width={190}
              height={190}
              border={10}
              borderRadius={190}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={scale}
              rotate={rotate}
              ref={editor}
            />
            <div className="control-block">
              <div className="control">
                <label htmlFor="anotherPhoto">
                  Choose another Photo<br/>
                  <input type="file"
                         id="anotherPhoto"
                         className="inputAvatar"
                         name="avatar"
                         accept="image/*"
                    // @ts-ignore
                         onChange={(event) => setNewAvatar(event.target.files[0])}
                  />
                </label>
              </div>
              <div className="control">
                <label htmlFor="scaleControl">
                  Scale: x{scale}<br/>
                  <input type="range"
                         id="scaleControl"
                         min="1"
                         max="2"
                         step="0.01"
                         value={scale}
                         onChange={(event) => setScale(parseFloat(event.target.value))}
                  />
                </label>
              </div>
              <div className="control">
                <label htmlFor="rotateControl">
                  Rotate: {rotate}<sup>o</sup><br/>
                  <input type="range"
                         id="rotateControl"
                         min="-45"
                         max="45"
                         value={rotate}
                         onChange={(event) => setRotate(parseFloat(event.target.value))}
                  />
                </label>
              </div>
            </div>
            <button type="submit" className="button">Upload photo</button>
          </form>
        </div>
      </div>
    </OverlayComponent>
  );
}