import React from 'react';
import {useTranslation} from "react-i18next";

interface TryServiceKartBlockProps {
}

export const TryServiceKartBlock: React.FC<TryServiceKartBlockProps> = ({}) => {
  const {t} = useTranslation();

  return <div className={"try-service-kart-block"}>
    <div className="content">
      <p className="looking-specialist-block">
        {t("tryServiceKart.looking specialist")}
      </p>
      <h2 >{t("tryServiceKart.title")}</h2>
      <div className="button-block">
        <button className="button create-an-order" type={"button"}>{t("tryServiceKart.createOrderButton")}</button>
        <p className={"or"}>{t("tryServiceKart.or")}</p>
        <button className="login-by-specialist" type={"button"} value={"signUp"}>{t("tryServiceKart.become specialist button")}</button>
      </div>
    </div>
  </div>
};