import React, {useEffect, useState} from 'react';
import './TestPageStyle.css';
import {useAuth} from "../hoc/AuthProvider";
import {useAlerts} from "../hoc/AlertsProvider";
import {useLanguage} from "../hoc/LanguageProvider";
import {useJsApiLoader, Autocomplete} from '@react-google-maps/api';
import {useTranslation} from "react-i18next";

// @ts-ignore
const GOOGLE_API_KEY: string = process.env.REACT_APP_GOOGLE_API_KEY;

interface TestPageProps {
  messagesAlerts: {success: string, warning: string, error: string};
  updateData: (data: any, path: string, successMessage: string, warningMessage: string, errorMessage: string) => void;
}

export const AddressAutocompleteComponent: React.FC<TestPageProps> = ({messagesAlerts, updateData}) => {
  const {user} = useAuth();
  const {t} = useTranslation();
  const {lang} = useLanguage();

  const {warningAlert} = useAlerts();

  const [address, setAddress] = useState(`${lang === "en" ? "Enter your full address" : "Geben Sie Ihre volle Adresse ein"}`);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [street, setStreet] = useState('');
  const [home, setHome] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [country, setCountry] = useState('');
  const [zip, setZip] = useState('');

  const [placeChanged, setPlaceChanged] = useState(false);

  useEffect(() => {
    if (user.id) {
      if (user.address.street && user.address.city && user.address.country) {
        setAddress(`${user.address.street}, ${user.address.home}, ${user.address.city}, ${user.address.country}`)
      }
      setStreet(user.address.street);
      setHome(user.address.home);
      setCity(user.address.city);
      // setRegion(user.address.region);
      setCountry(user.address.country);
      setZip(user.address.zip)
    }
  }, [user]);

  const [libraries] = useState<('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]>(['places']);
  const [getAutocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();

  const {isLoaded} = useJsApiLoader({
    id: 'gBlock',
    googleMapsApiKey: GOOGLE_API_KEY,
    region: 'ch',
    libraries
  });

  const onUnmount = React.useCallback(function callback(map) {
    map = null;
  }, []);

  const onAutoCompleteIsLoad = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    setAddress('');
    setHome('');
    setStreet('');
    setCity('');
    setRegion('');
    setCountry('');
    setZip('');
    setLatitude('');
    setLongitude('');

    if (getAutocomplete) {
      const place = getAutocomplete.getPlace();
      if (!place.geometry || !place.address_components) {
        warningAlert("Place not found")
      } else if (place.address_components) {
        place.address_components.forEach((item) => {
          const value = item.long_name;
          item.types.forEach((key) => {
            if (key === "street_number") {
              setHome(value)
            } else if (key === "route") {
              setStreet(value)
            } else if (key === "locality") {
              setCity(value)
            } else if (key === "administrative_area_level_1") {
              setRegion(value)
            } else if (key === "country") {
              setCountry(value)
            } else if(key === "postal_code") {
              setZip(value);
            }
          })
        });
        const {geometry} = place;
        if (geometry) {
          const {
            // @ts-ignore
            location: {lat, lng}
          } = geometry;
          setLatitude(lat())
          setLongitude(lng())
        }
        setPlaceChanged(true);
      }
    }
  }

  const onUpdateAddress = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (placeChanged) {
      if (street && home && city && country) {
        const data = {
          address: {
            street: street,
            home: home,
            city: city,
            region: region,
            country: country,
            zip: zip
          },
          geo: {
            lat: latitude,
            lng: longitude
          }
        }
        const path = "/user/update-user";

        updateData(data, path, messagesAlerts.success, messagesAlerts.warning, messagesAlerts.error);
      } else warningAlert("Enter all required fields");
    }
  }

  useEffect(() => {
    return () => {
    }
  }, []);

  return (
    isLoaded ? <div className="address-block">
      <form action="#" method={"PUT"} onSubmit={(event) => onUpdateAddress(event)}>
        <div className={"user-address-block input-block"}>
          <p>{t("account page.address")}</p>
          <div className="wrapper">
            <label htmlFor="autocomplete" className={"user-address-label"}>
              <Autocomplete className={"autocomplete"}
                            onLoad={onAutoCompleteIsLoad}
                            onPlaceChanged={onPlaceChanged}
                            onUnmount={onUnmount}
              >
                <input type="text" id="address" className="address" name="address" placeholder={address}/>
              </Autocomplete>
            </label>
          </div>
        </div>
        <div className={"input-block"}>
          {/*<p>{t("account page.address")}*</p>*/}
          <p>Street*</p>
          <div className="wrapper">
            <label htmlFor="street" className={"user-address-label"}>
              <input type="text" id="street" className="street" name="street" value={street} disabled={true}/>
            </label>
          </div>
        </div>
        <div className={"input-block"}>
          {/*<p>{t("account page.address")}*</p>*/}
          <p>Home*</p>
          <div className="wrapper">
            <label htmlFor="home" className={"user-address-label"}>
              <input type="text" id="home" className="home" name="home" value={home} disabled={true}/>
            </label>
          </div>
        </div>
        <div className={"input-block"}>
          {/*<p>{t("account page.address")}*</p>*/}
          <p>City*</p>
          <div className="wrapper">
            <label htmlFor="city" className={"user-address-label"}>
              <input type="text" id="city" className="city" name="city" value={city} disabled={true}/>

            </label>
          </div>
        </div>
        <div className={"input-block"}>
          {/*<p>{t("account page.address")}*</p>*/}
          <p>Region</p>
          <div className="wrapper">
            <label htmlFor="region" className={"user-address-label"}>
              <input type="text" id="region" className="region" name="region" value={region} disabled={true}/>
            </label>
          </div>
        </div>
        <div className={"input-block"}>
          {/*<p>{t("account page.address")}*</p>*/}
          <p>Country*</p>
          <div className="wrapper">
            <label htmlFor="country" className={"user-address-label"}>
              <input type="text" id="country" className="country" name="country" value={country} disabled={true}/>
            </label>
          </div>
        </div>
        <div className={"input-block"}>
          {/*<p>{t("account page.address")}*</p>*/}
          <p>Zip (postal code)</p>
          <div className="wrapper">
            <label htmlFor="zip" className={"user-address-label"}>
              <input type="text" id="zip" className="zip" name="zip" value={zip} disabled={true}/>
            </label>
          </div>
        </div>
        <div className={"button-block"}>
          <button type={"submit"}>{t("apply")}</button>
        </div>
      </form>
    </div> : <></>
  );
}