import React, {useEffect, useState} from "react";
import {useAuth} from "../components/hoc/AuthProvider";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {ExpertBlock} from "../components/ExpertBlock";
import {GoogleMapComponent} from "../components/GoogleMapComponent";

interface ExpertPageProps {
  categories: any,
  lang: string,
  testimonials: ITestimonials[],
  center: ILocation,
  setCenter: React.Dispatch<React.SetStateAction<ILocation>>
}

export const SubcategoriesPage: React.FC<ExpertPageProps> = ({lang, center, setCenter}) => {
  const {API_URL} = useAuth();
  const navigate = useNavigate();
  const {categoryLink, subcategoryLink} = useParams();
  const [category, setCategory] = useState<any>([]);
  const [subcategory, setSubcategory] = useState<any>([]);
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    fetch('/db/categories.json')
      .then(data => data.json())
      .then(categories => setCategory(categories.find((item: any) => item.categoryLink === categoryLink)))
      .catch(error => {
        console.log(error);
      });
  }, [subcategoryLink]);

  useEffect(() => {
    fetch(`${API_URL}/user/users/expert`)
      .then(data => data.json())
      .then((users: IUser[]) => setUsers(users))
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if(category === undefined) {
      navigate("/not-found-page", {replace: true});
    } else if(category?.subcategories?.length) {
      setSubcategory(category.subcategories.find((item: any) => item.categoryLink === subcategoryLink));
    }
  }, [category]);

  useEffect(() => {
    if(subcategory === undefined) {
      navigate("/not-found-page", {replace: true});
    }
  }, [subcategory]);

  useEffect(() => {
    return () => {
    }
  }, []);

  return <div>
    <main className="main-content">
      <div className="content">
        <h1>{subcategory !== undefined &&
          (lang === "de" ? subcategory.categoryDe : subcategory.categoryEn)}</h1>
      </div>
      {(subcategory !== undefined && center) && <GoogleMapComponent lang={lang} center={center} users={users} categories={subcategory}/>}
      <div className="content">
        <ExpertBlock lang={lang} users={users}/>
      </div>
    </main>
  </div>
}