import React, {createContext, useContext, useState} from 'react';

interface ILanguage {
  lang: string;
  setLang: React.Dispatch<React.SetStateAction<string>>;
}

export const LanguageContext = createContext<ILanguage>({} as ILanguage);

export const LanguageProvider: React.FC = ({children}) => {
  const [lang, setLang] = useState(localStorage.getItem("I18N_LANGUAGE") || "de");

  const value = {lang, setLang}

  return <LanguageContext.Provider value={value}>
    {children}
  </LanguageContext.Provider>
}

export const useLanguage = () => {
  return useContext(LanguageContext);
}