import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

interface ExpertBlockProps {
  users: IUser[];
  lang: string
}

export const ExpertBlock: React.FC<ExpertBlockProps> = ({users, lang}) => {
  const {t} = useTranslation("translation");

  return <div className="expert-block">
    {lang === "de" ? <h2><span>Experten</span>block</h2> : <h2><span>Expert</span> block</h2>}
    <div className="expert-block-wrapper">
      {users && users.map((user:any, index:number) => <div key={index} className="expert-cart">
        <div className="expert-cart-wrapper">
          <img className="user-avatar" src={user.avatar} alt="avatar"/>
          <div className="expert-data">
            <p className="expert">
              <span className="expert-name">{user.name}</span> <span className="expert-lastName">{user.lastName[0]}.</span>
            </p>
            <div className="expert-rate-block">
              <img className="expert-rate-icon" src="/image/icons/icon-great.svg" alt="icon rate"/>
              <div className={"expert-rate-wrapper"} >
                <span className={"expert-rate-description"}>{t("rating.great")}</span>
                <p className={"finally-jobs"}>(75 {t("rating.jobs")})</p>
              </div>
            </div>
            <Link to={`/profile/${user.username}`} className="button expert-cart-see-more">{t("seeMore")}</Link>
          </div>
        </div>
      </div>
      )}
    </div>
  </div>
};