import React from 'react';
import './InputTextFieldStyle.scss';
import {t} from "i18next";

interface InputFieldProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onValueInput: (event: React.ChangeEvent<HTMLInputElement>,
                 setValue: React.Dispatch<React.SetStateAction<string>>,
                 onBlurField: React.Dispatch<React.SetStateAction<typeof event>>) => void;
  onBlurField: (event: React.ChangeEvent<HTMLInputElement>,
                setValueInputError: React.Dispatch<React.SetStateAction<string>>,
                t: (value: string) => string) => void;
  valueInputError: string;
  setValueInputError: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  type: string;
  name: string;
  id: string;
}

export const InputTextField: React.FC<InputFieldProps> = ({value, setValue, onValueInput, onBlurField, valueInputError, setValueInputError, placeholder, type, name, id}) => {
  return (
    <>
      <div className={`input-container ${valueInputError ? 'error' : ''}`}>
        <input type={type}
               className="form-input"
               onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlurField(event, setValueInputError, t)}
               name={name}
               value={value}
               onChange={(event) => onValueInput(event, setValue, () => onBlurField(event, setValueInputError, t))}
               required
               id={id}
               placeholder={" "}
        />
        <div className="cut">{placeholder}</div>
        <label className={"placeholder"} htmlFor={id}>{placeholder}</label>
      </div>
      {valueInputError && <div className={'error-alert'}>{valueInputError}</div>}
    </>
  );
}