import React, {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
// import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';

interface DatePickerComponentProps {
  birthday: string;
  updateData: (data: any, path: string, successMessage: string, warningMessage: string, errorMessage: string) => void
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({birthday, updateData}) => {
  const [value, setValue] = React.useState<Date>(new Date(Date.parse(birthday)));
  const minDate: Date = new Date(new Date().setUTCFullYear(new Date().getUTCFullYear() - 90));
  const maxDate: Date = new Date();

  const onUpdateData = async (value: Date) => {
    const path = "/user/update-user";
    const data = {
      birthday: value
    }

    await updateData(data, path, "success", "warning", "error")
  };

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    {/*<StaticDatePicker
      displayStaticWrapperAs="desktop"
      openTo="year"
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      views={['year', 'month', 'day']}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => <TextField {...params} />}
    />*/}
    <DatePicker
      disableFuture
      minDate={minDate}
      maxDate={maxDate}
      openTo="year"
      views={['year', 'month', 'day']}
      value={value}
      onChange={(value): void => {
        if (value) {
          setValue(value);
        }
      }
    }
      onAccept={(value: any) => {
        onUpdateData(value);
      }
      }
      renderInput={(params: any) => {
        return <TextField {...params} placeholder={"mm/dd/yyyy"}/>
      }}
    />
  </LocalizationProvider>
};