import React, {createContext, useContext} from 'react';
import {useLanguage} from "./LanguageProvider";
import {toast} from "react-toastify";

interface IAlerts {
  passwordError: () => void;
  errorAlert: (message: string) => void;
  emailAlready: () => void;
  usernameAlready: () => void;
  successAlert: (message: string) => void;
  warningAlert: (message: string) => void;
  resendEmail: () => void;
}

export const AlertsContext = createContext({} as IAlerts);

export const AlertsProvider: React.FC = ({children}) => {
  const {lang} = useLanguage();

  const passwordError: () => void = () => toast.warning(`${lang === "en" ?
    "Username or password is wrong" : "Benutzername oder Passwort ist falsch"}`, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
  const errorAlert = (message: string) => toast.error(`${message}`, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
  const emailAlready = () => toast.warning(`${lang === "en" ? "Email already exists" : "E-Mail ist bereits vorhandenE-Mail ist bereits vorhanden"}`, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
  const usernameAlready = () => toast.warning(`${lang === "en" ? "User already exists" : "Benutzer existiert bereits"}`, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

  const warningAlert = (message: string) => toast.warning(`${message}`, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
  
  const successAlert = (message: string) => toast.success(`${message}`, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });
  const resendEmail = () => toast.success(`${lang === "en" ? "Email was sent to your mailbox" : "E -Mail wurde an Ihre Mailbox gesendet"}`)

  const value = {passwordError, errorAlert, emailAlready, usernameAlready, successAlert, warningAlert, resendEmail}

  return (
    <AlertsContext.Provider value={value}>
      {children}
    </AlertsContext.Provider>
  )
}

export const useAlerts = () => {
  return useContext(AlertsContext);
}