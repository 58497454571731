import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useVerify} from "../components/hoc/VerifyProvider";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

interface ResetPasswordPageProps {

}

export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({}) => {
  const {t} = useTranslation();

  const {onBlurPassword, onBlurConfirmPassword, onPasswordInput, onConfirmPasswordInput} = useVerify();

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [formValid, setFormValid] = useState<boolean>(false);

  const [inputType, setInputType] = useState<boolean>(true);
  const [confirmPassType, setConfirmPassType] = useState<boolean>(true);

  const [passwordInputError, setPasswordInputError] = useState<string>('');
  const [confirmPasswordInputError, setConfirmPasswordInputError] = useState<string>('');

  useEffect(() => {
    if (password && (password === confirmPassword)) {
      setFormValid(true);
    }
  }, [password, confirmPassword]);

  return (
    <div className={"form reset-password-form"}>
      <h3>Reset password form</h3>
      <div className="form-block">
        <form action="#" method={"POST"}>
          <div className={`input-container ${passwordInputError ? 'error' : ''}`}>
            <input type={inputType ? "password" : "text"}
                   className="form-input"
                   onBlur={((event: React.FocusEvent<HTMLInputElement>) => onBlurPassword(event, setPasswordInputError, confirmPassword, setConfirmPasswordInputError, t))}
                   name={"password"}
                   value={password}
                   onChange={(event) => onPasswordInput(event, setPassword, () => onBlurPassword(event, setPasswordInputError, confirmPassword, setConfirmPasswordInputError, t))}
                   required
                   id="password"
                   placeholder={" "}
            />
            {password && (inputType ?
              <VisibilityOutlinedIcon onClick={() => setInputType(!inputType)}/> :
              <VisibilityOffOutlinedIcon onClick={() => setInputType(!inputType)}/>)}
            <div className="cut">{t("form.password")}</div>
            <label className={"placeholder"} htmlFor="password">{t("form.password")}</label>
          </div>
          {passwordInputError && <div className={'error-alert'}>{passwordInputError}</div>}
          <div className={`input-container ${confirmPasswordInputError ? 'error' : ''}`}>
            <input type={confirmPassType ? "password" : "text"}
                   className="form-input"
                   onBlur={((event: React.FocusEvent<HTMLInputElement>) => onBlurConfirmPassword(event, password, setConfirmPasswordInputError, t))}
                   name={"confirmPassword"}
                   value={confirmPassword}
                   onChange={(event) => onConfirmPasswordInput(event, setConfirmPassword, () => onBlurConfirmPassword(event, password, setConfirmPasswordInputError, t))}
                   required
                   id="confirm-password"
                   placeholder={" "}
            />
            {confirmPassword && (confirmPassType ?
              <VisibilityOutlinedIcon onClick={() => setConfirmPassType(!confirmPassType)}/> :
              <VisibilityOffOutlinedIcon onClick={() => setConfirmPassType(!confirmPassType)}/>)}
            <div className="cut">{t("form.confirmPassword")}</div>
            <label className={"placeholder"} htmlFor="confirm-password">{t("form.confirmPassword")}</label>
          </div>
          {confirmPasswordInputError && <div className={'error-alert'}>{confirmPasswordInputError}</div>}
          <button type={"submit"} disabled={!formValid} className="button continue">{t("form.continue")}</button>
        </form>
      </div>
    </div>
  );
}