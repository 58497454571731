import React, {useEffect} from 'react';
import {PortalComponent} from "./PortalComponent";

interface OverlayComponentProps {
  onClose: () => void;
  isOpen?: boolean;
}

export const OverlayComponent: React.FC<OverlayComponentProps> = ({children, onClose, isOpen}) => {
  const addClass = () => {
    const body = document.querySelector('body');
    if (body && !body.classList.contains('overflow-hidden')) {
      body.classList.add('overflow-hidden');
    }
  };

  const removeClass = () => {
    const body = document.querySelector('body');
    if (body && body.classList.contains('overflow-hidden')) {
      body.classList.remove('overflow-hidden');
    }
  };

  useEffect(() => {
    if (!isOpen) {
      removeClass();
    } else {
      addClass();
    }
  }, [isOpen]);

  return !isOpen ? null : (
    <PortalComponent>
      <div className="popup">
        <div className="overlay" onClick={onClose}/>
        {children}
      </div>
    </PortalComponent>
  );
}