import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from "../components/hoc/AuthProvider";
import {useAlerts} from "../components/hoc/AlertsProvider";
import {useVerify} from "../components/hoc/VerifyProvider";
import {useTranslation} from 'react-i18next';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {InputTextField} from "../UI/InputFields/InputTextField";

interface LoginComponentProps {

}

export const LoginFormPage: React.FC<LoginComponentProps> = ({}) => {
  const {login, isAuth, user} = useAuth();
  const {onUsernameInput, onBlurUsername} = useVerify();
  const {errorAlert, warningAlert, successAlert} = useAlerts();

  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/";

  const redirectUser = () => {
        if (isAuth && !user.isActivated) {
          navigate('/auth/verify', {replace: true});
          console.log("Redirect!")
        } else navigate(`${fromPage}`, {replace: true})
      };

  const {t} = useTranslation();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [usernameInputError, setUsernameInputError] = useState<string>('');
  const [passwordInputError, setPasswordInputError] = useState<string>('');

  const [inputType, setInputType] = useState<boolean>(true);

  const [formValid, setFormValid] = useState<boolean>(false);

  const onBlurPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setPasswordInputError(t("form.emptyField"));
    } else setPasswordInputError('');
  };
  const onPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    onBlurPassword(event);
  };

  useEffect(() => {
    if ((username && !usernameInputError) && (password && !passwordInputError)) {
      setFormValid(true);
    } else setFormValid(false);
  }, [username, usernameInputError, password, passwordInputError]);

  const onLogin = async (event: any) => {
    event.preventDefault();
    setFormValid(false);
    const form = event.target;
    const username = form.username.value.trim();
    const password = form.password.value.trim();

    await login(username, password, setUsername, setPassword, setFormValid, errorAlert, redirectUser);
  };

  return (
    <>
      <div className="form login-form">
        <h3>{t("form.loginTitle")}</h3>
        <div className="form-block">
          <form action="src/components/LoginForm#" method={"POST"} name={"login"} onSubmit={event => onLogin(event)}>
            <InputTextField value={username}
                            setValue={setUsername}
                            onValueInput={onUsernameInput}
                            onBlurField={onBlurUsername}
                            valueInputError={usernameInputError}
                            setValueInputError={setUsernameInputError}
                            placeholder={t("form.username")}
                            type={"text"}
                            name={"username"}
                            id={"username"}
            />
            <div className={`input-container ${passwordInputError ? "error" : ""}`}>
              <input type={inputType ? "password" : "text"}
                     className="form-input"
                     required
                     id="password"
                     name={"password"}
                     onBlur={(event) => onBlurPassword(event)}
                     value={password}
                     onChange={(event) => onPasswordInput(event)}
                     placeholder={" "}/>
              {password && (inputType ?
                <VisibilityOutlinedIcon onClick={() => setInputType(!inputType)}/> :
                <VisibilityOffOutlinedIcon onClick={() => setInputType(!inputType)}/>)}
              <div className="cut">{t("form.password")}</div>
              <label className={"placeholder"} htmlFor="password">{t("form.password")}</label>
            </div>
            {passwordInputError && <div className={'error-alert'}>{passwordInputError}</div>}
            <button type={"submit"} disabled={!formValid} className="button continue">{t("form.continue")}</button>
            <div className="wrapper">
              <div className="remember-me">
                <div className="custom-check">
                  <input type="checkbox" className="check" id={"check"} value={"remember-me"} defaultChecked/>
                  <label htmlFor="check">{t("form.rememberMe")}</label>
                </div>
              </div>
              <Link to={"/auth/forgot-password"} className={"link-black"}>{t("form.forgotPass")}</Link>
            </div>
          </form>
          <div className="orWrapper">
            <span className={"or"}>{t("form.orLogin")}</span>
          </div>
          <div className="social-buttons-block">
            {/*<button type={"button"} className="social-button facebook-button">Facebook</button>*/}
            <button type={"button"} className="social-button google-button">Google</button>
            <button type={"button"} className="social-button apple-button">Apple</button>
            {/*<button type={"button"} className="social-button microsoft-button">Microsoft</button>*/}
          </div>
        </div>
        <div className="form-footer">
          <div className="form-footer-wrapper">
            <span>{t("form.nonReg")}</span>
            <Link to={"/auth/sign-up"} className={"signIn-button link-black"}>{t("form.join")}</Link>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  )
}