import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
const GOOGLE_API_KEY: string | undefined = process.env["REACT_APP_GOOGLE_API_KEY"];

const containerStyle = {
  width: '100%',
  height: '600px',
  margin: "auto"
};

interface ExpertOnMapProps {
  user: any
}

export const ExpertOnMap: React.FC<ExpertOnMapProps> = ({user}) => {
  const {t} = useTranslation("translation");
  const [, setMap] = useState<google.maps.Map>();
  const [, setMarker] = useState<null>(null);
  const [libraries] = useState<('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]>(['places']);

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    // @ts-ignore
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries
  });

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
    initZoomControl(map);

    setTimeout(() => {
      map.panTo({
        lat: user.geo.lat,
        lng: user.geo.lng
      })
    }, 1)
  };

  function initZoomControl(map: google.maps.Map) {
    (document.querySelector(".zoom-control-in") as HTMLElement).onclick =
      function () {
        map.setZoom(map.getZoom()! + 1);
      };

    (document.querySelector(".zoom-control-out") as HTMLElement).onclick =
      function () {
        map.setZoom(map.getZoom()! - 1);
      };

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.querySelector(".zoom-control") as HTMLElement
    );
  }

  const onUnmount = React.useCallback(function callback(map) {
    map = null;
  }, []);

  const pinGreenURL = new URL("/image/icons/markerPinGreen.svg", origin);

  return isLoaded ? <div className={"google-map-block"}>
    <h3>{t("profile page.map")}</h3>
    <div className="google-map-wrapper">
      {/*@ts-ignore*/}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: user.geo.lat,
          lng: user.geo.lng
        }}
        zoom={13}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false
        }}
        onClick={() => setMarker(null)}
      >
        {/*@ts-ignore*/}
        <Marker
          icon={{
            url: `${pinGreenURL}`,
            scaledSize: new google.maps.Size(40, 40),
          }}
          animation={google.maps.Animation.DROP}
          position={{
            lat: user.geo.lat,
            lng: user.geo.lng
          }}
        />
      </GoogleMap>
      <div style={{display:"none"}}>
        <div className="controls zoom-control">
          <button className="zoom-control-in" title="Zoom In">+</button>
          <button className="zoom-control-out" title="Zoom Out">−</button>
        </div>
      </div>
    </div>
  </div> : <></>
};