import React from "react";
import {useTranslation} from 'react-i18next';
import {ReactComponent as SliderLeftArrow} from "../../assets/image/icons/slider-left-arrow.svg";
import {ReactComponent as SliderRightArrow} from "../../assets/image/icons/slider-right-arrow.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination, Virtual} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {TestimonialSlider} from "./TestimonialSlider";
import {CreateOrderBlock} from "../CreateOrderBlock";

interface TestimonialsProps {
  testimonials: ITestimonials[]
}

export const Testimonials: React.FC<TestimonialsProps> = ({testimonials}) => {
  const {t} = useTranslation();

  return (
    <div className="testimonials">
      <div className="testimonials-container">
        <h2><span>{t("clientsSay.title.part1")}</span>{t("clientsSay.title.part2")}</h2>
        {testimonials.length >= 2 && <div className="swiper-button-group">
          <button type={"button"} className={"swiper-button prev-btn"}><SliderLeftArrow/></button>
          <button type={"button"} className={"swiper-button next-btn"}><SliderRightArrow/></button>
        </div>}
        <Swiper
          modules={[Pagination, Navigation, Virtual]}
          slidesPerView={2}
          spaceBetween={25}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn"
          }}
          pagination={{clickable: true}}
          virtual={true}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
              spaceBetween: 25
            },
            768: {
              slidesPerView: 1.5
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 25
            }
          }}
        >
          {testimonials.map((item, index) => (
            <SwiperSlide key={index}
                         virtualIndex={index}
            >
              <TestimonialSlider userAvatar={item.userAvatar}
                                 username={item.username}
                                 userLastName={item.userLastName}
                                 userCity={item.userCity}
                                 userCountry={item.userCountry}
                                 userText={item.userText}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <CreateOrderBlock>
        <p>{t("clientsSay.create order description")}</p>
      </CreateOrderBlock>
    </div>
  );
}